import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import ChevronRight from '../../../assets/icons/chevrons/ChevronRight';
import ChevronDown from '../../../assets/icons/chevrons/ChevronDown';
import EllipsisExpandIcon from '../../../assets/icons/EllipsisExpandIcon';
import EllipsisCollapseIcon from '../../../assets/icons/EllipsisCollapseIcon';
import { useSurvey } from '../SurveyContext';
import './SurveyAction.css';
import {API_KEY_MAPPINGS, PRINCIPLES_OF_DOCUMENTATION} from "../../../common/constants";

const ElementsTab = (props) => {
    const { handleEntityCheckboxClick, selectedEntities, getColorForEntity, annotations, scrollToSecton, scrollRef   } = useSurvey();
    const scrollKey = 'elementsTabScrollPosition'; // Key for scroll position in sessionStorage
    const collapseKey = 'elementsTabCollapsedStates'; // Key for collapsed states in localStorage

   const [collapsedStates, setCollapsedStates] = useState(() => {
        const savedCollapsedStates = sessionStorage.getItem(collapseKey);
        return savedCollapsedStates ? JSON.parse(savedCollapsedStates) : [];
    });    
    const [uniqueElements, setUniqueElements] = useState(['deficient_practice_statement', 'extent', 'sample', 'survey_action', 'recipient']);
    const [isVerticalArray, setIsVerticalArray] = useState([]);
    
    useEffect(() => {    
        return () => {
            sessionStorage.removeItem('elementsTabCollapsedStates');
        };
    }, []);

    useEffect(() => {
        const savedCollapsedStates = sessionStorage.getItem(collapseKey);
        if (savedCollapsedStates) {
            setCollapsedStates(JSON.parse(savedCollapsedStates));
        }
    }, []); 

    useEffect(() => {
        // Save collapsedStates to localStorage whenever it changes
        sessionStorage.setItem(collapseKey, JSON.stringify(collapsedStates)); 
    }, [collapsedStates]);

    const handleIconClick = (idx) => {
        setIsVerticalArray((prevState) => {
            const newState = [...prevState];
            newState[idx] = !newState[idx];
            return newState;
        });
    };

    const handleCollapseToggle = (key) => {
        setCollapsedStates(prevState => {
            if (prevState.includes(key)) {
                return prevState.filter(stateKey => stateKey !== key);
            } else {
                return [...prevState, key];
            }
        });
    };

    useLayoutEffect(() => {
        // Restore scroll position from sessionStorage
        const savedScrollPos = sessionStorage.getItem(scrollKey);
        if (savedScrollPos !== null && scrollRef.current) {
            // scrollRef.current.scrollTop = parseFloat(savedScrollPos);
        }
    }, []);

    const saveScrollPosition = () => {
        if (scrollRef.current) {
            const currentScrollPos = scrollRef.current.scrollTop;
            sessionStorage.setItem(scrollKey, currentScrollPos);
        }
    };

    const handleCheckboxChange = (element, event, type) => {
        event.preventDefault();
        event.stopPropagation();

        saveScrollPosition();

        handleEntityCheckboxClick(element, type);

        requestAnimationFrame(() => {
            if (scrollRef.current) {
                const savedScrollPos = sessionStorage.getItem(scrollKey);
                if (savedScrollPos !== null) {
                    scrollRef.current.scrollTop = parseFloat(savedScrollPos);
                }
            }
        });
    };

    const triggerScrollToSecton = (selectedElementsSection) => {
        scrollToSecton(selectedElementsSection);
    };

    useEffect(() => {
        window.addEventListener('beforeunload', saveScrollPosition);
        return () => {
            window.removeEventListener('beforeunload', saveScrollPosition);
        };
    }, []);

    const countOccurrences = (element) => {
        return annotations.filter((item) => item.type === element).length;
    };

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexShrink: 0 }}>
                <Box
                    sx={{
                        pt: 1,
                        pb: 1,
                        pl: 1.5,
                        pr: 2,
                    }}
                >
                    <FormControlLabel
                        className="my-checkbox-class"
                        control={
                            <Checkbox
                                onClick={(event) => handleCheckboxChange(uniqueElements, event, 'all')}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        event.preventDefault();
                                        handleCheckboxChange(uniqueElements, event, 'all');
                                    }
                                }}
                                checked={uniqueElements.length === selectedEntities.length}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 28,
                                    },
                                    '&&& input': {  // Increased specificity for direct child input
                                        top: 'unset !important',
                                    }
                                }}
                            />
                        }
                        label="Select All"
                    />
                </Box>
            </div>

            <div ref={scrollRef} style={{ flex: '1 1 auto', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px 4px 0 0' }} id='panel_scrollbar' className='all_scrollbars'>
                {uniqueElements.length > 0 && uniqueElements.map((element, index) => {
                    const key = `${element}-${index}`; // Generate a unique key for each element

                    return (
                        <Box key={key} sx={{ border: '1px solid #D6D7D9', borderRadius: 0, pl: 0, pt: 1.5, pb: 1.5, pr: 2, mb: 0, backgroundColor: '#ffffff' }}>
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center', p: 0, pb: !collapsedStates.includes(key) ? 0 : 1, gap: '4px' }}>
                                    <Checkbox
                                        onClick={(event) => handleCheckboxChange(element, event, 'single')}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' || event.key === ' ') {
                                                event.preventDefault();
                                                handleCheckboxChange(element, event, 'single');
                                            }
                                        }}
                                        sx={{
                                            '& .MuiSvgIcon-root': { fontSize: 28 },
                                        }}
                                        checked={selectedEntities.includes(element)}
                                        disabled={countOccurrences(element) === 0}
                                    />
                                    <Box sx={{ bgcolor: getColorForEntity(element), p: 1, borderRadius: 1, display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body2">
                                            <b>{PRINCIPLES_OF_DOCUMENTATION[element].displayLabel}</b> {countOccurrences(element)}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}></Box>
                                    <IconButton 
                                        sx={{ color: '#0071BC', visibility: countOccurrences(element) === 0 ? 'hidden' : 'visible' }} 
                                        onClick={() => handleCollapseToggle(key)}
                                    >
                                        {!collapsedStates.includes(key) ? <ChevronRight style={{fill: '#0071BC'}} /> : <ChevronDown style={{fill: '#0071BC'}} />}
                                    </IconButton>
                                </Box>
                            </div>
                            {collapsedStates.includes(key) && (
                                <>
                                    {annotations.filter(data => data.type === element).map((data, idx) => {
                                        const count = countOccurrences(element);
                                        return (
                                            <Box
                                                key={idx}
                                                sx={{
                                                    padding: '8px 0',
                                                    borderRadius: 0,
                                                    pt: 1,
                                                    pb: 1,
                                                    ml: 2,
                                                    pr: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    borderTop: idx === 0 ? '1px solid #D6D7D9' : 'none',
                                                    borderBottom: idx !== count - 1 ? '1px solid #D6D7D9' : 'none'
                                                }}
                                            >
                                                <Typography
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') {
                                                            triggerScrollToSecton(data.section);
                                                        }
                                                    }}
                                                    tabIndex='0'
                                                    onClick={() => triggerScrollToSecton(data.section)} 
                                                    variant="body2" id="element-text-box" sx={{ flexGrow: 1, pr: 1 }}>
                                                    {data.text}
                                                </Typography>
                                                <Box sx={{ display: 'block', alignSelf: 'flex-start' }}>
                                                    <IconButton style={{visibility: 'hidden'}} onClick={() => handleIconClick(idx)}>
                                                        {isVerticalArray[idx] ? <EllipsisCollapseIcon /> : <EllipsisExpandIcon />}
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </>
                            )}
                        </Box>
                    )
                })}
            </div>
        </div>
    );
};

export default ElementsTab;
