import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
  
class Header extends Component {
	
	render() {
		
			return (
				<div>
					<Row>
						<Col className=' title ds-text-heading--3xl' style={{ marginBottom: '0px' }}><title> 2567</title></Col>
						<Col className='title' md={2} />
					</Row>
					<Row>
						<Col className='title'></Col>
						<Col className='title pb-3' md={1}>
							<div className="form-right">
								<Button style={{backgroundColor: "#0071BC"}} className="btn btn-secondary btn-sm" onClick={() => this.props.loginHarporCognito()}>Login</Button>
							</div>
						</Col>
					</Row>
				</div>
			)
		}
	}

export default Header;