//Libraries
import React, {useState, useEffect, forwardRef, useRef, useMemo } from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import ChevronDown from '../../../assets/icons/chevrons/ChevronDown';
import ChevronRight from '../../../assets/icons/chevrons/ChevronRight';
import FlagIcon from '../../../assets/icons/FlagIcon';
import SeverityIndicator from '../../../assets/icons/SeverityIndicator';
//Components
import { useSurvey } from '../SurveyContext';
import InfoIcon from '../../../base-components/InfoIcon';
//Styles
import './FacilityList.css';
import {getSurveyDetails, getSurveys} from "../../../common/api";
import {SURVEVY_DETAILS_API_PROJECTIONS} from "../../../common/constants";
import FacilityCardSkeleton from './FacilityCardSkeleton';
import ReactTooltip from "react-tooltip";

const FacilityCard = ({ facility, activeSurveyDef, setActiveSurveyDef, isFirst  }) => {
    const { updateDeficiencyText, updateAnnotations, updateSelectedDeficiency, selectedDeficiency, selectedFilter } = useSurvey();
    const [collapsed, setCollapsed] = useState(true);
    const [validSurveyTypes] = useState(['Standard','Complaint','Infection Control'])

    //Needed to remove start from filter object cuz it was resetting when hitting scroll pagination.
    const filterStartKeyFromFiltersObject = (filter) => {
        const { start, ...filtered } = filter;
        return filtered;
      };

      useEffect(() => {
        setCollapsed(true);
    }, [JSON.stringify(filterStartKeyFromFiltersObject(selectedFilter))]);

    const handleCollapseToggle = () => {
        setCollapsed(!collapsed);
    };

    const handleDeficiencyBoxClick = (ccn, tag, inspectionDate, index) => async (event) => {
        const uniqueKey = `${ccn}-${tag}-${inspectionDate}-${index}`;
        setActiveSurveyDef(uniqueKey);
        if(!selectedDeficiency || (selectedDeficiency.facility_id !== ccn  ||
            selectedDeficiency.inspection_date !== inspectionDate  ||
            selectedDeficiency.deficiency_tag !== tag)) {
            let requestParams = {
                filter:{
                    "inspection_date": [inspectionDate],
                    "facility_id": [ccn],
                    "sods.deficiency_tag": [tag]
                },
                projection: SURVEVY_DETAILS_API_PROJECTIONS
            };
            let surveyDetails = await getSurveyDetails(requestParams);
            if(surveyDetails.length>0) {
                let newSelectedDeficiency = {}
                surveyDetails[0].sods.forEach((deficiency) => {
                    if(deficiency.deficiency_tag === tag)
                        newSelectedDeficiency = deficiency
                })
                newSelectedDeficiency.facility_id = ccn;
                newSelectedDeficiency.inspection_date = inspectionDate;
                updateDeficiencyText(newSelectedDeficiency.inspection_text);
                updateAnnotations(newSelectedDeficiency.components);
                updateSelectedDeficiency(newSelectedDeficiency);
            }
        }
    };

    const handleKeyDown = (ccn, tag, inspectionDate, index) => (event) => {
        if (event.key === 'Enter') {
            handleDeficiencyBoxClick(ccn, tag, inspectionDate, index)(event);
        }
    };

    return (
        <>
            <Box onClick={handleCollapseToggle}
                sx={{

                    borderBottom: '1px solid #ccc',
                    borderTop:'none',
                    borderRadius: 0,
                    p: 2,
                    mb: 0,
                    backgroundColor: '#ffffff',
                    '&:hover': {
                      backgroundColor: '#F8F8F8',
                    },
                }}
            >
                <Grid container alignItems="center" style={{paddingBottom: '8px'}}>
                    <Grid item xs>
                        <Box  sx={{ 
                            bgcolor: '#D6D7D9', 
                            p: 0.5, 
                            borderRadius: 0,
                            display: 'inline-block',
                             
                        }}>
                            <Typography variant="body2" sx={{fontSize: '11px', textAlign: 'right', fontWeight: 400}}>
                                CCN: <b style={{fontSize: '14px'}}>{facility.facility_id}</b>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 400, textAlign: 'right', paddingRight: '8px' }}>
                            <b>{facility.inspection_date}</b>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton size="small">
                            {collapsed ? <ChevronRight style={{fill:'#323232'}} /> :  <ChevronDown style={{fill:'#323232'}} />}
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" sx={{paddingBottom: '8px'}}>
                    <Grid item xs>
                        <Typography sx={{fontWeight: 400, fontSize: '14px', color: '#5B616B'}} component="div">
                            {facility.facility_name.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" sx={{ fontSize: '11px', fontWeight: 400 }}>
                                EventID: <b style={{ fontSize: '14px' }}>{facility.eventid}</b>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ 
                            bgcolor: '#F8F8F8',
                            p: 0.5,
                            borderRadius: 1,
                            display: 'inline-block',
                            ml: 1 // Margin left to separate it from the first box
                        }}>
                            <Typography variant="body2" sx={{ color: '#0071BC', fontWeight: 600, fontSize: '14px' }}>
                                {facility.sods.length}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" sx={{ color: '#242424', fontSize: '11px', fontWeight: 400, ml: 1, paddingRight: '38px' }}>
                            {facility.sods.length === 1 ? 'Deficiency' : 'Deficiencies'}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {!collapsed && (
                <>
                    {facility.sods.map((deficiency, index) => {
                        // const deficiencyType = deficiency.survey_type[0].toLowerCase().replace(/\s+/g, '');
                        // const deficiencyType = (deficiency.compliant?'Complaint':deficiency.standard?'Standard':'').toLowerCase().replace(/\s+/g, '');
                        const uniqueKey = `${facility.facility_id}-${deficiency.deficiency_tag}-${facility.inspection_date}-${index}`;
                        return (
                            <Box key={uniqueKey}
                                onClick={handleDeficiencyBoxClick(facility.facility_id, deficiency.deficiency_tag, facility.inspection_date, index)}
                                onKeyDown={handleKeyDown(facility.facility_id, deficiency.deficiency_tag, facility.inspection_date, index)}
                                tabIndex={0}
                                sx={{
                                    borderBottom: '1px solid #ccc',
                                    p: 2,
                                    mb: 0,
                                    backgroundColor: '#ffffff',
                                    borderLeft: activeSurveyDef === uniqueKey ? '4px solid #0096F4' : '4px solid #ffffff',
                                    '&:hover': {
                                    backgroundColor: '#F8F8F8'
                                    },
                                    '&:focus-visible': {
                                    outline: 'none',
                                    backgroundColor: '#F2F9FF',
                                    }
                                }}>
                                <Grid container alignItems="center" spacing={1} sx={{pt:.5}}>
                                    <Grid item xs>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <div hidden={deficiency.undercitation_flag !== 'Yes'} key={uniqueKey} data-tip data-for={uniqueKey}
                                            style={{ display: 'flex', alignItems: 'center' }}>
                                            <SeverityIndicator style={{ fill: '#FDB81E', width: '16px', height: '16px', verticalAlign: 'middle' }} />
                                        </div>

                                        <ReactTooltip id={uniqueKey} place='top' effect="solid">
                                            Flagged for Severity
                                        </ReactTooltip>

                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            F-{deficiency.deficiency_tag}
                                        </Typography>
                                    </div>
                                        <Typography sx={{fontSize: '12px', fontWeight: 400}}variant="body2" color="textSecondary">
                                            {deficiency.regulatory_group
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item alignSelf="flex-start">
                                            <InfoIcon type='severityIcon'>{deficiency.scope_severity}</InfoIcon>
                                    </Grid>
                                    <Grid item>
                                        <IconButton style={{visibility: 'hidden'}} size="small">
                                            {<FlagIcon />}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, gap: '8px' }}>
                                {validSurveyTypes.map((survey) => {
                                    const activeIcon = deficiency.survey_type.find((str) => str === survey)
                                    const surveyTypeKey = `${survey}-${uniqueKey}`
                                    return (
                                        <>
                                            <div key={surveyTypeKey} data-tip data-for={surveyTypeKey}
    >
                                                <InfoIcon fill={activeIcon} type='surveyIcon' variant={survey.replace(/\s+/g, '')} />
                                            </div>
                                            <ReactTooltip id={surveyTypeKey} place='top' effect="solid">
                                                {survey}
                                            </ReactTooltip>
                                        </>
                                    );
                                })}

                                </Box>
                                    {/* <InfoIcon type='surveyIcon' variant={deficiencyType} /> */}
                                    {/* <Typography variant="body2" color="textSecondary" sx={{ mx: 1, fontSize: '11px', fontWeight: 400 }}>
                                        {deficiency.survey_type[0]}
                                    </Typography> */}
                                    <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                                        <Typography variant="body2" sx={{fontSize: '11px', fontWeight: 400, paddingRight: '38px'}}>
                                            Surveyor ID: <b style={{fontSize: '14px'}}>{deficiency.surveyor_id}</b>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                </>
            )}
        </>
    );
};

const FacilityList = forwardRef(({ activeSurveyDef, setActiveSurveyDef, loading, skeletonCount, initialLoad }, ref) => {
    const { surveyDefList, surveysLoading } = useSurvey();
    return (
        <Box ref={ref} sx={{ padding: '8px 4px 0 1px', width: '100%' }}>
            {surveyDefList.map((facility, index) => {
                const uniqueKey = `${facility.ccn}-${index}`;
                return (
                    <FacilityCard
                        key={uniqueKey}
                        facility={facility}
                        activeSurveyDef={activeSurveyDef}
                        setActiveSurveyDef={setActiveSurveyDef}
                        isFirst={index === 0}
                    />
                );
            })}
            {!initialLoad && !loading && surveyDefList.length === 0 && (
                <Box sx={{p: 2, mb: 0}}>
                <Typography style={{color:'#5B616B', display: 'flex', fontSize: '14px', fontWeight: 400, textAlign: 'center', justifyContent:'center'}}>
                    No results are found.
                    <br/>
                    Please update your filters.
                </Typography>
                </Box>

            )}
            {loading &&
            Array.from({ length: skeletonCount }).map((_, index) => (
                <FacilityCardSkeleton key={`skeleton-${index}`} />
            ))
            }

        </Box>
    );
});

const FacilityContainer = ({ }) => {
    const [activeSurveyDef, setActiveSurveyDef] = useState('');
    const [loadMore, setLoadMore] = useState(false);
    const { selectedFilter, setSelectedFilter, surveyDefList, updateSurveyDefList,
        surveyCount, surveysLoading, updateSurveysLoading, initialLoad,
        scrollSurveyListToTop, updateScrollSurveyListToTop} = useSurvey();
    // const [loading, setLoading] = useState(false);
    const [skeletonCount] = useState(1);
    const scrollRef = useRef(null);
    const hasTriggeredScroll = useRef(false);
    useEffect(() => {
        if(scrollSurveyListToTop) {
            handleScrollToTop();
        }
    },[scrollSurveyListToTop])

    const handleScrollToTop = () => {
        scrollRef.current.scrollTo({top:0});
    };
    const handleScroll = () => {
        if (scrollRef.current && !surveysLoading && !hasTriggeredScroll.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (selectedFilter.start <= surveyCount && scrollTop + clientHeight >= scrollHeight - 50) {
                hasTriggeredScroll.current = true; // Prevent multiple triggers
                handleEndOfScroll();
            }
        }
    };

    const handleEndOfScroll = () => {
        updateSurveysLoading(true);
        setLoadMore(true);
    };

    useEffect(() => {
        const currentScrollRef = scrollRef.current;
        if (currentScrollRef && !surveysLoading) {
            currentScrollRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [surveysLoading]); // Only attach event when loading is false

    useEffect(() => {
        if(loadMore) {
            if(surveyCount>0 && selectedFilter.start + 10 <= surveyCount) {
                let newFilter = { ...selectedFilter, start: selectedFilter.start + 10 };
                setSelectedFilter(newFilter);
                getSurveys(newFilter).then((surveys) => {
                    updateSurveyDefList([...surveyDefList, ...surveys]);
                    updateSurveysLoading(false);
                    setLoadMore(false);
                    hasTriggeredScroll.current = false;
                });
            } else {
                updateSurveysLoading(false);
                setLoadMore(false);
                hasTriggeredScroll.current = false;
            }
        }
    }, [loadMore]); // Only attach event when loading is false
    return (
        <div ref={scrollRef} style={{ display: 'flex', flex: 1, overflow: 'auto'}} className='all_scrollbars'>
            <FacilityList ref={scrollRef} activeSurveyDef={activeSurveyDef} setActiveSurveyDef={setActiveSurveyDef}
                          loading={surveysLoading} skeletonCount={skeletonCount} initialLoad={initialLoad} />
        </div>
    );
};

export default FacilityContainer;