export default function AttributesIcon({ style }) {
    const defaultFill = "#5B616B"; 
    const fill = style?.fill || defaultFill; 

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill='none' xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M8.15576 1.243C7.21529 1.243 6.28649 1.30922 5.4236 1.43687C4.55951 1.56469 3.78719 1.75033 3.14843 1.97846C2.5038 2.20869 2.03797 2.467 1.7463 2.71848C1.45379 2.97069 1.40072 3.15591 1.40072 3.25535C1.40072 3.35661 1.45559 3.54156 1.7463 3.79222C2.03492 4.04107 2.49789 4.29991 3.14843 4.53224C4.44663 4.99589 6.24824 5.2677 8.15576 5.2677C9.09623 5.2677 10.025 5.20149 10.8879 5.07384C11.752 4.94601 12.5243 4.76037 13.1631 4.53224C13.8077 4.30202 14.2735 4.04371 14.5652 3.79222C14.8577 3.54001 14.9108 3.35479 14.9108 3.25535C14.9108 3.15591 14.8577 2.97069 14.5652 2.71848C14.2735 2.467 13.8077 2.20869 13.1631 1.97846C12.5243 1.75033 11.752 1.56469 10.8879 1.43687C10.025 1.30922 9.09623 1.243 8.15576 1.243ZM5.24141 0.205313C6.16869 0.0681377 7.15857 -0.00195312 8.15576 -0.00195312C9.15295 -0.00195312 10.1428 0.0681376 11.0701 0.205313C11.9962 0.34231 12.8504 0.544832 13.5818 0.806034C14.3073 1.06514 14.9283 1.38775 15.3782 1.77561C15.8272 2.16274 16.1558 2.66277 16.1558 3.25535C16.1558 3.84793 15.8272 4.34796 15.3782 4.73509C14.9283 5.12295 14.3073 5.44556 13.5818 5.70467C12.8504 5.96587 11.9962 6.1684 11.0701 6.30539C10.1428 6.44257 9.15295 6.51266 8.15576 6.51266C6.15 6.51266 4.19861 6.22928 2.7297 5.70467C1.99668 5.44288 1.37925 5.11956 0.933339 4.73509C0.489526 4.35243 0.155762 3.85289 0.155762 3.25535C0.155762 2.66277 0.484342 2.16275 0.933339 1.77561C1.38318 1.38775 2.0042 1.06514 2.7297 0.806034C3.46107 0.544832 4.31533 0.34231 5.24141 0.205313Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M0.77824 12.1182C1.12203 12.1182 1.40072 12.3969 1.40072 12.7406C1.40072 12.875 1.50779 13.166 2.09411 13.5287C2.65662 13.8766 3.51025 14.1896 4.59271 14.4128C5.66791 14.6345 6.89757 14.753 8.15576 14.753C9.41394 14.753 10.6436 14.6345 11.7188 14.4128C12.8013 14.1896 13.6549 13.8766 14.2174 13.5287C14.8037 13.166 14.9108 12.875 14.9108 12.7406C14.9108 12.3969 15.1895 12.1182 15.5333 12.1182C15.8771 12.1182 16.1558 12.3969 16.1558 12.7406C16.1558 13.5313 15.581 14.149 14.8723 14.5874C14.1398 15.0406 13.1308 15.3928 11.9702 15.6321C10.8024 15.8729 9.48762 15.9979 8.15576 15.9979C6.82389 15.9979 5.50914 15.8729 4.34129 15.6321C3.1807 15.3928 2.17169 15.0406 1.43917 14.5874C0.730465 14.149 0.155761 13.5313 0.155762 12.7406C0.155762 12.3969 0.434455 12.1182 0.77824 12.1182Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M0.77824 8.95654C1.12202 8.95654 1.40072 9.23524 1.40072 9.57902C1.40072 9.71333 1.50779 10.0044 2.09411 10.3671C2.65662 10.715 3.51025 11.028 4.59271 11.2512C5.66791 11.4729 6.89757 11.5914 8.15576 11.5914C9.41394 11.5914 10.6436 11.4729 11.7188 11.2512C12.8013 11.028 13.6549 10.715 14.2174 10.3671C14.8037 10.0044 14.9108 9.71333 14.9108 9.57902C14.9108 9.23524 15.1895 8.95654 15.5333 8.95654C15.8771 8.95654 16.1558 9.23524 16.1558 9.57902C16.1558 10.3697 15.581 10.9874 14.8723 11.4258C14.1398 11.8789 13.1308 12.2312 11.9702 12.4705C10.8024 12.7113 9.48762 12.8363 8.15576 12.8363C6.82389 12.8363 5.50914 12.7113 4.34129 12.4705C3.1807 12.2312 2.17169 11.8789 1.43917 11.4258C0.730465 10.9874 0.155762 10.3697 0.155762 9.57902C0.155762 9.23524 0.434454 8.95654 0.77824 8.95654Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M0.77824 5.79468C1.12203 5.79468 1.40072 6.07337 1.40072 6.41716C1.40072 6.55147 1.50779 6.8425 2.09411 7.20519C2.65662 7.55315 3.51025 7.86613 4.59271 8.08933C5.66791 8.31103 6.89757 8.42951 8.15576 8.42951C9.41394 8.42951 10.6436 8.31103 11.7188 8.08933C12.8013 7.86613 13.6549 7.55315 14.2174 7.20519C14.8037 6.8425 14.9108 6.55147 14.9108 6.41716C14.9108 6.07337 15.1895 5.79468 15.5333 5.79468C15.8771 5.79468 16.1558 6.07337 16.1558 6.41716C16.1558 7.20786 15.581 7.82555 14.8723 8.26395C14.1398 8.71708 13.1308 9.06933 11.9702 9.30864C10.8024 9.54944 9.48762 9.67446 8.15576 9.67446C6.82389 9.67446 5.50914 9.54944 4.34129 9.30864C3.1807 9.06933 2.17169 8.71708 1.43917 8.26395C0.730465 7.82555 0.155761 7.20786 0.155762 6.41716C0.155762 6.07337 0.434455 5.79468 0.77824 5.79468Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M0.77824 2.63281C1.12202 2.63281 1.40072 2.91151 1.40072 3.25529V12.7407C1.40072 13.0844 1.12202 13.3631 0.77824 13.3631C0.434455 13.3631 0.155762 13.0844 0.155762 12.7407V3.25529C0.155762 2.91151 0.434455 2.63281 0.77824 2.63281Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M15.5334 2.63281C15.8772 2.63281 16.1558 2.91151 16.1558 3.25529V12.7407C16.1558 13.0845 15.8772 13.3631 15.5334 13.3631C15.1896 13.3631 14.9109 13.0845 14.9109 12.7407V3.25529C14.9109 2.91151 15.1896 2.63281 15.5334 2.63281Z"/>
        </svg>
    );
}

  