import * as React from 'react';
import { styled } from '@mui/material/styles';
import MUIButton from '@mui/material/Button';
import '../variable.css'

const hexToRGBA = (hex, opacity) => {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};


const BootstrapButton = styled(({ size, variant, outline, disabled, ...otherProps }) => (
    <MUIButton role="button" disableRipple size={size || 'standard'} variant={variant || 'primary'} disabled={disabled} {...otherProps} />
))(({ variant = 'primary', outline, disabled, size = 'standard' }) => {
    const focusOpacity = (variant.toLowerCase() === 'error') ? 1 : 0.3;
    
    variant = variant.toLowerCase();

    const sizeStyles = {
        standard: {
            fontSize: '14px',
            padding: '8px 4px',  
        },
        lg: {
            fontSize: '16px', 
            padding: '10px 14px',
        },
    };
    const appliedSizeStyles = sizeStyles[size]; 
    const baseStyles = {
        fontWeight: 500,
        boxShadow: 'none',
        textTransform: 'none',
        border: '1px solid',
        lineHeight: 1.5,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        ...appliedSizeStyles,
    };

    if (disabled) {

        return {
            ...baseStyles,
            backgroundColor: outline ? '#FFFFFF' : `var(--disabled-main)`,
            borderColor: outline ? `var(--disabled-secondary)` : `var(--disabled-main)`,
            color: `var(--disabled-text)`,
            '&:hover': {},
            '&:active': {},
            '&:focus': {},
        };
    }

    else{


    return {
        ...baseStyles,
        backgroundColor: outline ? 'white' : `var(--${variant}-main)`,
        borderColor: outline ? `var(--${variant}-outlineBorder)` : `var(--${variant}-main)`,
        color: outline ? `var(--${variant}-outlineText)` : `var(--${variant}-text)`,
        // backgroundColor: outline ? '#FFFFFF' : main,
        // borderColor: outline && variant.toLowerCase() === 'primary' ? outlineBorder : main,
        // color: outline ? outlineText : text,
        '&:hover': {
            backgroundColor: `var(--${variant}-secondary)`,
            borderColor: variant === 'tertiary' ? 'none' : `var(--${variant}-secondary)`,
            color: variant === 'tertiary' ? `var(--${variant}-text2)` : `var(--${variant}-text)`

        },
        '&:focus': {
            ...(variant !== 'tertiary' ? {
                boxShadow: `0 0 0 0.2rem ${hexToRGBA('#0071BC', focusOpacity)}`,
                backgroundColor: `var(--${variant}-secondary)`,
                borderColor: `var(--${variant}-secondary)`,
                color: `var(--${variant}-text)`
            } : {}),
            ...(variant === 'tertiary' ? {
                outline: `2px solid ${`var(--${variant}-text2)`}`,
                color: `var(--${variant}-text2)`,

            } : {})
        }
    };
}

});

export default BootstrapButton;