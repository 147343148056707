import { Skeleton, Box, Grid } from '@mui/material';

const FacilityCardSkeleton = () => (
    <Box
        sx={{
            borderBottom: '1px solid #ccc',
            borderRadius: 0,
            p: 2,
            mb: 0,
            backgroundColor: '#ffffff',
        }}
    >
        {/* Header */}
        <Grid container alignItems="center" style={{ paddingBottom: '8px' }}>
            <Grid item>
                <Skeleton variant="circular" width={28} height={28} />
            </Grid>

            <Grid item xs>
                <Box
                    sx={{
                        bgcolor: 'white',
                        p: 0.5,
                        borderRadius: 0,
                        display: 'inline-block',
                    }}
                >
                    <Skeleton variant="text" width="88px" height="24px" />
                </Box>
            </Grid>

            <Grid item>
                <div style={{paddingLeft: '8px', paddingRight: '32px'}}>
                    <Skeleton style={{textAlign: 'right'}} variant="text" width="80px" height="24px" />
                </div>
            </Grid>
        </Grid>

        {/* Facility Name */}
        <Grid container alignItems="center" sx={{ paddingLeft: '20px', paddingBottom: '8px' }}>
            <Grid item xs>
                <div style={{paddingRight: '32px'}}>
                    <Skeleton variant="text" width="100%" height="21px" />
                </div>
            </Grid>
        </Grid>

        {/* Deficiency Count */}
        <Grid container alignItems="center" sx={{ paddingLeft: '20px' }}>
            <Grid item xs>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Skeleton variant="rounded" width="14px" height="24px" />
                    <Skeleton variant="text" width="80px" sx={{ ml: 1 }} />
                </Box>
            </Grid>
        </Grid>
    </Box>
);

export default FacilityCardSkeleton;