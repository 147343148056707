import React, { useEffect, useState, useRef } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import Button from './base-components/Button';
import ExitIcon from './assets/icons/ExitIcon';

export default function App(props) {
  // Default configurations
  const defaultWarningTime = 25; // in minutes
  const defaultLogoutTime = 30;  // in minutes

  // Determine whether to use DDB data or default configurations
  const maxInactiveTimeBeforeWarning = props.ddbData ? props.maxInactiveTimeBeforeWarning : defaultWarningTime;
  const maxInactiveTimeBeforeLogout = props.ddbData ? props.maxInactiveTimeBeforeLogout : defaultLogoutTime;

  // Convert the times to milliseconds
  const timeout = maxInactiveTimeBeforeLogout * 60 * 1000;
  const promptBeforeIdle = timeout - (maxInactiveTimeBeforeWarning * 60 * 1000);

  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);
  const hasPromptedRef = useRef(false);

  const { getRemainingTime, activate, reset } = useIdleTimer({
    onIdle: () => {
      setState('Idle');
      setOpen(false);
      props.onTimeout(200, 'Successful');
    },
    onActive: () => {
      setState('Active');
      setOpen(false);
    },
    onPrompt: () => {
      setState('Prompted');
      hasPromptedRef.current = true;
      setOpen(true);
    },
    promptBeforeIdle,
    timeout,
  });


  // Log the configuration values when props update
  useEffect(() => {
    if (props.ddbData) {
      reset(); // Restart the idle timer with the new configuration
    }
  }, [props.ddbData, props.maxInactiveTimeBeforeLogout, props.maxInactiveTimeBeforeWarning, timeout, promptBeforeIdle, reset]);


  const handleOnClose = () => {
    props.onTimeout(200, 'Successful');
  };

  const calculateTimeMessage = () => {
    const minutes = Math.floor(remaining / 60);
    const seconds = remaining % 60;
    const minutesText = minutes === 1 ? 'minute' : 'minutes';
    const secondsText = seconds === 1 ? 'second' : 'seconds';

    if (minutes > 0 && seconds > 0) {
      return `${minutes} ${minutesText} and ${seconds} ${secondsText}`;
    } else if (minutes > 0) {
      return `${minutes} ${minutesText}`;
    } else {
      return `${seconds} ${secondsText}`;
    }
  };

  useEffect(() => {
    const interval = workerTimers.setInterval(() => {
      const timeLeft = Math.ceil(getRemainingTime() / 1000);
      setRemaining(timeLeft);
      // console.log(`Remaining time (seconds):`, timeLeft);
      // Only open the modal if the user is prompted and the modal is currently closed
      if (hasPromptedRef.current && !open) {
        setOpen(true);
      }
    }, 1000);
  
    return () => {
      workerTimers.clearInterval(interval);
    };
  }, [getRemainingTime, open]);

  const handleStillHere = () => {
    activate();
    setState('Active');
    setOpen(false); // Close the modal when the user confirms they are still active
    hasPromptedRef.current = false; // Reset the prompt flag to prevent reopening
  };

  return (
    <Modal
      open={open}
      onClose={handleStillHere}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 2,
      }}>
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Typography id="modal-title" sx={{fontWeight: 700, fontSize: '24px'}} component="h3">
            Are you still there?
          </Typography>
          <IconButton onClick={handleStillHere}>
            <ExitIcon/>
          </IconButton>
          
        </Box>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          You have been inactive for a while.<br />
          Your session will end in <strong>{calculateTimeMessage()}</strong>.
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Select "Continue session" below if you want more time.
        </Typography>
        <Box
          mt={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px',
          }}
        >
          <Button
            variant="Primary"
            outline
            onClick={handleOnClose}
            id="logout-button"
            aria-label="Logout"
            size="lg"
          >
            Logout
          </Button>
          <Button
            variant="Primary"
            onClick={handleStillHere}
            id="continue-session-button"
            aria-label="Continue session"
            size="lg"
          >
            Continue Session
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
