// Libraries
import React, {useEffect, useRef, useState} from 'react';
import { styled, alpha } from '@mui/material/styles';
import { IconButton, TextField, InputAdornment, Menu, MenuItem, Button } from '@mui/material';
import UpArrowIcon from '../../../assets/icons/UpArrowIcon';
import SearchIcon from '../../../assets/icons/SearchIcon';
import ChevronDown from '../../../assets/icons/chevrons/ChevronDown';
// Components
import FacilityList from './FacilityList';
// Styles
import '../../../variable.css';
import './FacilityList.css';
import {useSurvey} from "../SurveyContext";
import {getSurveyCount, getSurveys} from "../../../common/api";
import {filterSurveys, getFilterOptions} from '../../../common/api';
import {API_KEY_MAPPINGS, SURVEY_LIST_SORT_MAPPINGS, DefaultFilter } from "../../../common/constants";
import DownArrowIcon from "../../../assets/icons/DownArrowIcon";
import {fetchDefaultFilter} from "../../../common/utils";

const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    padding: '16px 16px 16px 0px',
    borderBottom: '1px solid #D6D7D9'
};

const selectContainerStyle = {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
};

const textStyle = {
    fontSize: '14px',
    color: '#5B616B',
    fontWeight: '400',
    paddingLeft: '16px'
};


const StyledMenu = styled((props) => {
    const { anchorEl } = props;
    const autoFocus = anchorEl && anchorEl.getAttribute('data-interaction-type') === 'keyboard';
    return (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            autoFocus={autoFocus}
            {...props}
        />
    );
    })(() => ({
        '& .MuiPaper-root': {
            borderRadius: 0,
            marginTop: 8,
            minWidth: 180,
            boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                color: '#242424',
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: '#242424',
                    marginRight: 8,
                },
                '&:hover': {
                    backgroundColor: '#F8F8F8',
                },
                '&:focus': {
                    backgroundColor: '#F2F9FF',
                },
                '&:hover:focus': {
                    backgroundColor: '#EBF5FF',
                },
                '&:active': {
                    backgroundColor: '#FFFFFF',
                }
            },
        },
    }));

    const SurveySelectionPanel = () => {
    // const [loading, setLoading] = useState(false); // Loading state
    // const [skeletonCount] = useState(1);
    // const hasTriggeredScroll = useRef(false);
    // Ref connects ScrollUp Button to scroll bar.
    const scrollRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortBy, setSortBy] = useState('Date');
    const [sortByOrder, setSortByOrder] = useState('asc');
    const interactionRef = useRef('mouse'); // Ref to track the interaction type
    const { selectedFilter, setSelectedFilter, surveyDefList,
        updateSurveysLoading, updateInitialLoadFlag,
        updateSurveyDefList, surveyCount, updateSurveyCount,
        scrollSurveyListToTop, updateScrollSurveyListToTop} = useSurvey();
    // const handleScroll = () => {
    //     if (scrollRef.current && !loading && !hasTriggeredScroll.current) {
    //         const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    //         if (scrollTop + clientHeight >= scrollHeight - 50) {
    //             hasTriggeredScroll.current = true; // Prevent multiple triggers
    //             console.log('End of scroll');
    //             handleEndOfScroll();
    //         }
    //     }
    // };
    // const handleEndOfScroll = () => {
    //     setLoading(true);
    // };
    const loadDefaultSurveysList = async () => {
        updateSurveysLoading(true);
        let surveys = await getSurveys(selectedFilter);
        let count = await getSurveyCount(selectedFilter);
        await updateSurveyDefList(surveys);
        updateSurveysLoading(false);
        updateInitialLoadFlag(false)
        await updateSurveyCount(count);
    };
    useEffect(() => {
        setSelectedFilter(fetchDefaultFilter());
    }, []);
    useEffect(() => {
        loadDefaultSurveysList().then(() => {});
    }, []);
    const handleSortOrderValue = async () => {
        setSortByOrder(sortByOrder==='asc'?'desc': 'asc');
        // TODO: Suresh -- Need to fix it.
        selectedFilter.start = 0;
        selectedFilter.sort.order = sortByOrder;
        // updateSurveysLoading(true);
        updateScrollSurveyListToTop(true);
        let surveys = await getSurveys(selectedFilter)
        setSelectedFilter(selectedFilter);
        await updateSurveyDefList(surveys);
        updateScrollSurveyListToTop(false);
        // updateSurveysLoading(false);
    };

    const handleClick = (event) => {
        interactionRef.current = 'mouse';
        event.currentTarget.dataset.interactionType = 'mouse';
        setAnchorEl(event.currentTarget);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            interactionRef.current = 'keyboard';
            event.currentTarget.dataset.interactionType = 'keyboard';
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = async (option) => {
        if(option) {
            selectedFilter.sort.field = option.apiKey;
            selectedFilter.start = 0;
            setSelectedFilter(selectedFilter);
            updateScrollSurveyListToTop(true);
            updateSurveysLoading(true);
            // selectedFilter.sort.order = 'asc';
            let surveys = await getSurveys(selectedFilter)
            await updateSurveyDefList(surveys);
            updateSurveysLoading(false);
            updateScrollSurveyListToTop(false);
            setAnchorEl(null);
            if (option) {
                setSortBy(option.displayLabel);
            }
        }
        if(!option){
            //This means the user is not changing their input
            setAnchorEl(null)
        }
    };

    const open = Boolean(anchorEl);

    // useEffect(() => {
    //     const currentScrollRef = scrollRef.current;
    //     if (currentScrollRef && !loading) {
    //         currentScrollRef.addEventListener('scroll', handleScroll);
    //     }
    //     return () => {
    //         if (currentScrollRef) {
    //             currentScrollRef.removeEventListener('scroll', handleScroll);
    //         }
    //     };
    // }, [loading]); // Only attach event when loading is false

    return (
        <div style={{ display: 'flex', flexDirection:'column', height: '100vh', boxSizing:'box-sizing', padding: '0px 8px 0px 0px', overflow: 'hidden' }}>
            <div style={{ display: 'flex', flexDirection:'column', height: '100%' }}>
                <p hidden style={{fontSize: '14px', fontWeight: '500', lineHeight: '14px' }}>
                    Keyword Search
                </p>
                <TextField
                    hidden
                    variant="outlined"
                    placeholder="Search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
                <div style={containerStyle}>
                    <span style={textStyle}>
                        {surveyDefList.length} of {surveyCount} results
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: 'auto' }}>
                        <div style={selectContainerStyle}>
                            <Button
                                id="sort-by-button"
                                aria-controls={open ? 'sort-by-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : 'false'}
                                variant="contained"
                                disableElevation
                                onClick={handleClick}
                                onKeyDown={handleKeyDown}
                                endIcon={<ChevronDown style={{fill: surveyCount === 0 ? '#5B616B': '#0071BC'}} />}
                                disabled={surveyCount===0}
                                sx={{
                                    padding: '6px 8px',
                                    borderRadius: '24px',
                                    color: '#0071BC',
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #0096F4',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#FFFFFF',
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: '#FFFFFF',
                                        color: '#5B616B',
                                        border: '1px solid #D6D7D9',
                                    },
                                }}
                            >
                                By {sortBy}
                            </Button>
                            <StyledMenu
                                id="sort-by-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'sort-by-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => handleClose(null)}
                                autoFocus={interactionRef.current === 'keyboard'}
                            >
                                {Array.from(SURVEY_LIST_SORT_MAPPINGS).map(([key, value]) => (
                                    <MenuItem onClick={() => handleClose(value)} disableRipple>
                                        {value.displayLabel}
                                    </MenuItem>
                                    ))
                                }

                            </StyledMenu>
                        </div>
                        <IconButton onClick={handleSortOrderValue} disabled={surveyCount===0}>
                            {sortByOrder === 'asc'?<DownArrowIcon/>: <UpArrowIcon/>}
                        </IconButton>
                    </div>
                </div>
                <div ref={scrollRef} style={{ display: 'flex', flex: 1, overflow: 'auto'}} id='panel_scrollbar' className='all_scrollbars'>
                    <FacilityList/>
                </div>
            </div>
        </div>
    );
};

export default SurveySelectionPanel;
