import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import '../../variable.css';
let QuickSightEmbedding = require('amazon-quicksight-embedding-sdk');

const Dashboard = (props) => {
    const [embedUrl, setEmbedUrl] = useState('');

    useEffect(() => {
        fetch(`/api/quicksight/${props.board}`)
            .then((response) => response.json())
            .then((json) => {
                if (json.EmbedUrl !== '') {
                    let embedUrl = json.EmbedUrl;
                    setEmbedUrl(embedUrl);
                    embedDashboard(embedUrl);
                }
            });
    }, [props.board]);

    const embedDashboard = (embedUrl) => {
        const container = document.getElementById('embeddingContainer');
        if (container) {
            const options = {
                url: embedUrl,
                container: container,
                footerPaddingEnabled: true,
            };
            QuickSightEmbedding.embedDashboard(options);

            
        }
    };

    return (
        <>
             <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', border: 'solid #D6D7D9 10pt', flex: 1, padding: '24px' }}>
                <h1 style={{ fontWeight: '700', fontSize: '20px', color: '#205493', padding: '4px 8px', margin: 0 }}>
                    DASHBOARDS
                </h1>
                <hr style={{ border: '1px solid #C3C5C6', opacity: 1 }} />
                <Container fluid style={{ flex: '1 1 auto', overflow: 'hidden', padding: 0}}>
                    <div id="embeddingContainer" className="responsive-container" style={{ height: '100%', width: '100%' }}></div>
                </Container>
                </div>
        </>
    );
};

export default Dashboard;
