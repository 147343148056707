//Libraries
import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tabs, Tab, Typography, Box, Container } from '@mui/material';
//Components
import { useSurvey } from '../SurveyContext';
import SurveyActionPanel from '../SurveyActions/SurveyActionPanel';
import FullReportInfo from './FullReport/FullReportInfo';
import Feedback from './Feedback/Feedback';
//Styles
import '../SurveyColorVariables.css';

function CustomTabPanel(props) {
    const { children, value, index, annotations, sections, handleSelection, sectionsRef  } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            className='custom-tab-panel'
            style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: index === 0 ? '16px' : '0'
            }}
        >
            {value === index && (
                <Typography component="div">
                    {index === 0 ? (
                        <FullReportInfo
                            sections={sections}
                            annotations={annotations}
                            handleSelection={handleSelection}
                            sectionsRef={sectionsRef}
                        />
                    ) : (
                        children
                    )}
                </Typography>
            )}
        </div>
    );
}

function SurveyInfo() {
    const { selectedEntities, getColorForEntity, annotations, defText, selectedDeficiency } = useSurvey();
    const [value, setValue] = useState(0);
    //Maybe move to the context.
    const [currentSurveyInfo] = useState({
        deficiency_tag: '644', surveyor_id: '40476'
    });
    const [sections, setSections] = useState([]);
    const [visibleHighlights, setVisibleHighlights] = useState([]);
    const sectionsRef = useRef([]);

 
    useEffect(() => {
        // Only when sections have been populated, assign the sectionRefs
        if (sections.length > 0 && sectionsRef.current.length > 0) {
            console.log('Assigned sectionRefs:', sectionsRef.current);
        }
    }, [sections]);
    
    useEffect(() => {
        //Need to update to wait until def text if available. We get this from defText.
        const breakTextIntoSections = (text) => {
            let sections = text.split('<BR/>');
            sections = sections.map((line) => {
                if (line.length === 0 || /^[^\w]+$/.test(line)) {
                    return null;
                }
                return { content: line };
            }).filter(section => section !== null);
      
            return sections;
        };
        const initialSections = breakTextIntoSections(defText);
      
        setSections(initialSections);
      }, [defText]);

    const handleSelection = (selectedText, section, sectionText) => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;

        const range = selection.getRangeAt(0);
        selectedText = selection.toString().trim();

        const parentNode = range.commonAncestorContainer.parentNode;
        const fullParentText = parentNode.textContent;

        const relativeStart = range.startOffset + fullParentText.indexOf(range.commonAncestorContainer.textContent);
        const startIndex = sectionText.indexOf(selectedText, relativeStart - selectedText.length);
        const endIndex = startIndex + selectedText.length;

        if (startIndex !== -1 && selectedText.length > 0) {
            const newAnnotation = createComment(selectedText, startIndex, endIndex, section);
            setAnnotations(prevAnnotations => [...prevAnnotations, newAnnotation]);
        }

        selection.removeAllRanges();
    };

    function createComment(selectedText, start, end, section) {
        return {
            color: '#E1F3F8',
            text: selectedText,
            start,
            end,
            section: section
        };
    }

    useEffect(() => {
        //Function used to handle 
            const updatedHighlights = filterVisibleAnnotations(annotations);
            if(updatedHighlights.length !== 0){
                console.log('Updating visible highlights', updatedHighlights);
            }
            setVisibleHighlights(updatedHighlights);
    }, [selectedEntities, selectedDeficiency]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterVisibleAnnotations = (entityArray) => {
        // entityArray contains all annotations objects for that def.
        // we first filter out objects based on entities selected.
        const visibleHighlights = entityArray.filter(entity => selectedEntities.includes(entity.type));
        if(visibleHighlights.length !== 0){
            console.log('Visible Highlights Array', visibleHighlights)
        }
        const sectionedOverlapArray = groupBySectionCheckOverlap(visibleHighlights);
        if(sectionedOverlapArray.length !== 0){
            console.log('Data Seperated By Sections with overlap status', sectionedOverlapArray)
        }
        //This array will contain the final output array of all objects for the selected entities and overlap.
        const finalProcessedAnnotationsArray = [];
        if (sectionedOverlapArray.length > 0) {
            // Filter out Sections with hasOverlap set to false
            const overlappingGroups = sectionedOverlapArray.filter(section => section.hasOverlap);
        
            if (overlappingGroups.length > 0) {
              // Extract the group data from the overlapping groups
              const groupedSectionData = overlappingGroups.map(section => section.section);
              console.log('Sections that have overlap:', groupedSectionData);
        
              // Process the overlapping groups
              groupedSectionData.forEach(section => {
                console.log('Processing section:', section);
                const modifiedData = groupAndProcessObjects(section); // Process the section
                console.log('Updated Object Data for overlap:', modifiedData);
                finalProcessedAnnotationsArray.push(...modifiedData); // Add processed data to the final array
              });
            } else {
              console.log('No overlapping sections to process.');
            }
        
            // Handle non-overlapping groups
            const nonOverlappingGroups = sectionedOverlapArray.filter(section => !section.hasOverlap);
            nonOverlappingGroups.forEach(section => {
                // We add non-overlapping groups directly to final array with no processing.
                finalProcessedAnnotationsArray.push(...section.section); 
            });
          }
          if(finalProcessedAnnotationsArray.length !== 0)
            {
                console.log('Final Processed Annotations Array:', finalProcessedAnnotationsArray);
            }
    
        return finalProcessedAnnotationsArray;
    }

    const groupBySectionCheckOverlap = (visibleHighlights) => {
        // Create an object to store groups by section
        const groupsBySection = {};
      
        visibleHighlights.forEach((item) => {
          const { section } = item;
          if (!groupsBySection[section]) {
            groupsBySection[section] = [];
          }
          groupsBySection[section].push(item);
        });
      
        const groupedData = Object.values(groupsBySection);
      
        // Function to check if there are overlapping indices in a group
        const checkOverlaps = (group) => {
          for (let i = 0; i < group.length; i++) {
            for (let j = i + 1; j < group.length; j++) {
              if (group[i].start < group[j].end && group[j].start < group[i].end) {
                return true;
              }
            }
          }
          return false;
        };
      
        // Add a `hasOverlap` key to each group and rename `group` to `section`
        const result = groupedData.map(group => {
          return {
            section: group,
            hasOverlap: checkOverlaps(group)
          };
        });
      
        return result;
      };

      const groupAndProcessObjects = (data) => {
        if (!Array.isArray(data) || data.length === 0) {
          console.log('Invalid or empty data input.');
          return [];
        }
      
        // Sort data by start index
        data.sort((a, b) => a.start - b.start);
        console.log('Sorted data:', data);
      
        const groups = [];
        let currentGroup = [data[0]];
        let currentEnd = data[0].end;
      
        // Identify overlapping and contiguous groups
        for (let i = 1; i < data.length; i++) {
          const currentObj = data[i];
          
          if (currentObj.start <= currentEnd) {
            console.log(`Object ${i} overlaps with or is contiguous to the previous group.`);
            currentGroup.push(currentObj);
            currentEnd = Math.max(currentEnd, currentObj.end);
          } else {
            console.log(`Object ${i} does not overlap, forming a new group.`);
            groups.push(currentGroup);
            currentGroup = [currentObj];
            currentEnd = currentObj.end;
          }
        }
      
        // Add the last group
        groups.push(currentGroup);
        console.log('Grouped data:', groups);
      
        // Process each group
        const result = groups.map((group, index) => {
          if (group.length === 1) {
            console.log(`Group ${index} has no overlaps. Returning as is.`);
            return group;
          } else {
            console.log(`Group ${index} has overlaps. Processing group:`, group);
            return modifyAndSplitObjects(group);
          }
        });
      
        // Flatten the result array
        const flattenedResult = result.flat();
        console.log('Final result after processing:', flattenedResult);
        return flattenedResult;
      };

    const modifyAndSplitObjects = (data) => {
    const overlapColor = getColorForEntity('overlap');
    console.log('Processing group with modifyAndSplitObjects:', data);

    if (!Array.isArray(data) || data.length === 0) {
        console.log('Empty group received.');
        return [];
    }

    // Assume the first object is the parent and the rest are overlaps
    const [parent, ...overlaps] = data;

    // Sort overlaps by start index for accurate segmentation
    overlaps.sort((a, b) => a.start - b.start);

    const segments = [];
    let currentIndex = parent.start;

    overlaps.forEach((overlap, index) => {
        // Create non-overlapping segment before the current overlap
        if (currentIndex < overlap.start) {
        console.log(`Creating segment from ${currentIndex} to ${overlap.start - 1}`);
        segments.push({
            ...parent,
            start: currentIndex,
            end: overlap.start - 1,
            key: uuidv4() // Generate a unique key for each segment. !! important for library to not duplicate objects
        });
        }

        // Add the overlap itself, changing its color
        console.log(`Modifying overlap segment starting at index ${overlap.start} with color change.`);
        segments.push({
        ...overlap,
        color: overlapColor,
        });

        // Update the current index to after the overlap
        currentIndex = overlap.end + 1;
    });

    // Add the remaining segment after the last overlap
    if (currentIndex <= parent.end) {
        console.log(`Creating remaining segment from ${currentIndex} to ${parent.end}`);
        segments.push({
        ...parent,
        start: currentIndex,
        end: parent.end,
        key: uuidv4()
        });
    }

    console.log('Segments after processing:', segments);
    return segments;
    };
    
    if (defText === '') {
        return null;
      }

    return (
        
     // Main Container with flex layout
        (selectedDeficiency!=undefined) &&
<div style={{ display: 'flex', flexDirection: 'column', height: '100vh', boxSizing: 'border-box', overflow: 'hidden' }}>
    <div style={{ padding: '0 0 0 16px', flex: '0 0 auto', boxSizing: 'border-box' }}>
        {/* Header Section */}
        <div style={{ padding: '0 0 16px 0' }}>
            <div style={{  display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <div style={{ padding: '0 8px', color: '#205493', fontWeight: 700, fontSize: '20px' }}>
                    F-{selectedDeficiency.deficiency_tag}
                </div>
            </div>
        </div>
        <hr style={{ margin: 0, border: '1px solid #C3C5C6', opacity: 1 }} />
    </div>

    {/* Main Content Section with Scrollable Panels */}
    <div style={{ display: 'flex', flex: 1, overflow: 'hidden', padding: '0 0 0 16px'}}>
        <div style={{ paddingRight: '16px', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            {/* Tabs Section */}
            <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', flexShrink: 0, borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        '.MuiTabs-indicator': { backgroundColor: '#0096F4', height: '6px' },
                        '.MuiTab-root': {
                            color: '#242424',
                            fontWeight: 400,
                            textTransform: 'none',
                            '&.Mui-selected': { color: '#242424', fontWeight: 600 }
                        }
                    }}
                >
                    <Tab label="Deficiency Text" />
                    <Tab hidden label={value === 1 ? "Feedback" : "Feedback (2)"} />
                </Tabs>
            </Box>

            {/* Scrollable Content Section */}
            <div tabindex="0" id='panel_scrollbar' className='all_scrollbars' style={{ flex: 1, overflowY: 'auto', boxSizing: 'border-box' }}>
                <CustomTabPanel value={value} index={0} annotations={visibleHighlights} sections={sections} handleSelection={handleSelection}  sectionsRef={sectionsRef} />
                <CustomTabPanel value={value} index={1} >
                    <Feedback />
                </CustomTabPanel>
            </div>
        </div>

        {/* Right Side Panel */}
        <div style={{ flexShrink: 0 }}>
            <SurveyActionPanel />
        </div>
    </div>
</div>


    );
}

export default SurveyInfo;