import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../../variable.css';
import './Sidebar.css';
import AnalyticsIcon from '../../assets/icons/AnalyticsIcon';
import DocumentIcon from '../../assets/icons/DocumentIcon';
import MenuCollapseIcon from '../../assets/icons/MenuCollapseIcon';
import MenuExpandIcon from '../../assets/icons/MenuExpandIcon';
import { IconButton } from '@mui/material';
import InfoIcon from '../../base-components/InfoIcon';
import {PageTitles} from '../../common/constants'


const Sidebar = ({ isExpanded, toggleExpand }) => {
    const location = useLocation();

    useEffect(() => {
        document.title = (PageTitles[location.pathname]);
    }, [location.pathname]);

    return (
        <div >
            <div className={isExpanded  ? "sidebar-header-expanded" : "sidebar-header-collapsed"} onClick={toggleExpand}>
            {isExpanded ? 
                <IconButton >
                    <MenuCollapseIcon style={{fill: '#0071BC'}}/>
                </IconButton> 
            :
                <IconButton>
                    <MenuExpandIcon />
                </IconButton>
                }
            </div>
                <ul className="sidebar-menu">
                <li className={location.pathname === '/dashboards' ? 'activeLink' : 'inactiveLink'}>
                    <div className="icon-text-container">
                        <NavLink className="sidebar-link" to="/dashboards">
                            <AnalyticsIcon style={{fill: location.pathname === '/dashboards' ? '#0071BC' : undefined}}/>
                            {isExpanded && <span>Dashboards</span>}
                        </NavLink>
                    </div>
                </li>
                <li className={location.pathname === '/surveys' ? 'activeLink' : 'inactiveLink'}>
                    <div className="icon-text-container">
                        <NavLink className="sidebar-link" to="/surveys">
                            <DocumentIcon style={{fill: location.pathname === '/surveys' ? '#0071BC' : undefined}}/>
                            {isExpanded && <span>Surveys</span>}
                        </NavLink>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
