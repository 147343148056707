// SurveyActionPanel Component

import React, { useState } from 'react';
import { Box, IconButton, Tabs, Tab, Container } from '@mui/material';
import EllipsisExpandIcon from '../../../assets/icons/EllipsisExpandIcon';
import MenuExpandIcon from '../../../assets/icons/MenuExpandIcon';
//Components
import { useSurvey } from '../SurveyContext';
import ElementsTab from './ElementsTab';
import VectorIcon from '../../../assets/icons/VectorIcon';
//Styling
import '../SurveyColorVariables.css'
import './SurveyAction.css'

const SurveyActionPanel = (props) => {
    const { annotations, defText } = useSurvey();
    const [actionsMenuOpen, toggleMenu] = useState(false);
    const [value, setValue] = useState(0);

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleToggleMenu = () => {
        toggleMenu((prevState) => !prevState);
    };

    function CustomTabPanel(props) {
        const { children, value, index } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                className='custom-tab-panel-nopadding'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '0px',
                    flexGrow: 1,
                    overflow: 'hidden', // Prevent overflow from spilling out of this container
                }}
            >
                {value === index && children}
            </div>
        );
    }

    if (defText === '') {
        return null;
    }

    return (
        <>
            <Container style={{
                width: actionsMenuOpen? '24vw' : 'auto',
                display: 'flex',
                // maxWidth: '24vw',
                height: '100%',
                flexGrow: 1,
                paddingLeft: '0',
                paddingTop: '22px',
                boxSizing: 'border-box',
                overflow: 'hidden',
                paddingRight: 0,
                paddingBottom: 0
            }}>
                <div style={{ borderLeft: actionsMenuOpen ? '1px solid #D6D7D9' : 'none', height: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    {actionsMenuOpen ? (
                        <>
                            <Box sx={{ display: 'flex', borderBottom: '1px solid #D6D7D9', p: 1 }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}>
                                <VectorIcon />
                                <span style={{ flex: 1 }}>Actions</span>
                                <IconButton sx={{ color: '#0071BC' }} onClick={handleToggleMenu} style={{ marginLeft: 'auto' }}>
                                    <MenuExpandIcon style={{fill:'#0071BC'}} />
                                </IconButton>
                                </div>
                            </Box>
                            <Box sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                <Box sx={{ flexShrink: 0, borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs
                                        value={value}
                                        variant="scrollable"
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        sx={{
                                            '.MuiTabs-indicator': {
                                                backgroundColor: '#0096F4',
                                                height: '6px'
                                            },
                                            '.MuiTab-root': {
                                                color: '#242424',
                                                fontWeight: 400,
                                                textTransform: 'none',
                                                '&.Mui-selected': {
                                                    color: '#242424',
                                                    fontWeight: 600,
                                                }
                                            }
                                        }}
                                    >
                                        <Tab sx={{ color: '#242424' }} label={`Elements (${annotations.length})`} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0}>
                                    <div style={{ flexGrow: 1, overflow: 'auto' }}>
                                        <ElementsTab onCheckboxClick={props.onCheckboxClick}/>
                                    </div>
                                </CustomTabPanel>
                            </Box>
                        </>
                    ) : (
                        <Box>
                            <div style={{...buttonContainerStyle, border: actionsMenuOpen ? 'none' : '1px solid #D6D7D9',}}>
                                <IconButton onClick={handleToggleMenu}>
                                    <EllipsisExpandIcon style={{fill: '#5B616B'}}/>
                                </IconButton>
                            </div>
                        </Box>
                    )}
                </div>
            </Container>
        </>
    );
};

export default SurveyActionPanel;
