export default function DocumentIcon({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M1.27273 1V13L12.7273 13L12.7273 3.26297L9.33281 1H1.27273ZM0 13V1C0 0.447716 0.569819 0 1.27273 0H9.33281C9.63443 0 9.92625 0.0841655 10.1562 0.237491L13.5507 2.50047C13.8357 2.69047 14 2.9693 14 3.26297V13C14 13.5523 13.4302 14 12.7273 14H1.27273C0.569819 14 0 13.5523 0 13Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M2.54545 7.5C2.54545 7.22386 2.78966 7 3.09091 7H9.63636C9.93761 7 10.1818 7.22386 10.1818 7.5C10.1818 7.77614 9.93761 8 9.63636 8H3.09091C2.78966 8 2.54545 7.77614 2.54545 7.5Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M2.54545 4.5C2.54545 4.22386 2.77338 4 3.05455 4H7.12727C7.40844 4 7.63636 4.22386 7.63636 4.5C7.63636 4.77614 7.40844 5 7.12727 5H3.05455C2.77338 5 2.54545 4.77614 2.54545 4.5Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M2.54545 10.5C2.54545 10.2239 2.78966 10 3.09091 10H9.63636C9.93761 10 10.1818 10.2239 10.1818 10.5C10.1818 10.7761 9.93761 11 9.63636 11H3.09091C2.78966 11 2.54545 10.7761 2.54545 10.5Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M8.90909 3V0.5H10.1818V3H13.3636V4H10.1818C9.47891 4 8.90909 3.55228 8.90909 3Z"/>
        </svg>
    );
}