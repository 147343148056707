//Libraries
import React from "react";
import { PanelResizeHandle } from "react-resizable-panels";
//Styles
import styles from "./styles.module.css";

export default function ResizeHandle() {
    return (
        <PanelResizeHandle style={{ cursor: 'col-resize', backgroundColor: '#D6D7D9'}} id={styles.ResizeHandleOuter}>
            <div className={styles.ResizeHandleInner}/>
        </PanelResizeHandle>
    );
}