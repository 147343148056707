export default function DownArrowIcon({ style }) {
    const defaultFill = "#5B616B"; 
    const fill = style?.fill || defaultFill; 

    return (
        <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={fill} fill-rule="evenodd" clip-rule="evenodd" d="M7 13.998C6.85187 13.998 6.71054 13.9281 6.61045 13.8052L2.13891 8.31498C1.94169 8.07284 1.95622 7.6966 2.17137 7.47464C2.38651 7.25268 2.7208 7.26903 2.91801 7.51118L6.47155 11.8742L6.47155 0.592817C6.47155 0.264334 6.70814 -0.00195359 7 -0.00195356C7.29186 -0.00195354 7.52846 0.264334 7.52846 0.592817L7.52845 11.8742L11.082 7.51118C11.2792 7.26903 11.6135 7.25268 11.8286 7.47464C12.0438 7.69661 12.0583 8.07284 11.8611 8.31498L7.38955 13.8052C7.28946 13.9281 7.14813 13.998 7 13.998Z"/>
        </svg>
    );
  }
