import React, { useState, useRef, useEffect } from 'react';
import { MenuItem, IconButton, Paper } from '@mui/material';
import InfoIcon from '../../base-components/InfoIcon';
import ChevronDown from '../../assets/icons/chevrons/ChevronDown';
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const containerRef = useRef(null);
  const menuItemRef = useRef(null);
  const lastInteractionRef = useRef('mouse');
  const navigate = useNavigate();

  const initializeNameTag = (nameTag) => {
    const words = nameTag.split(' ');
    const initials = words.map(word => word.charAt(0)).join('');
    return initials;
  };

  const toggleMenu = () => {
    setMenuVisible(prev => !prev);
  };

  const handleLogout = () => {
    setMenuVisible(false);
    props.sharedLogout();
  };

  const handleKeyDown = (event) => {
    if (!menuVisible && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
      lastInteractionRef.current = 'keyboard';
      setMenuVisible(true);
    }
  };

  const handleGlobalKeyDown = (event) => {
    if (menuVisible && event.key === 'Tab') {
      setMenuVisible(false);
    }
  };

  const handleMenuItemKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const nextElement = menuItemRef.current.nextElementSibling;
      if (nextElement) {
        nextElement.focus();
      } else {
        menuItemRef.current.parentNode.firstChild.focus();
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const previousElement = menuItemRef.current.previousElementSibling;
      if (previousElement) {
        previousElement.focus();
      } else {
        menuItemRef.current.parentNode.lastChild.focus();
      }
    } else if (event.key === 'Escape') {
      setMenuVisible(false);
    }
  };

  const handleKeyDownLogo = (event) => {
    if(event.key === 'Enter'){
      openRootPage();
    }
  };

  const openRootPage = () => {
    navigate("/dashboards");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        lastInteractionRef.current = 'mouse';
        setMenuVisible(false);
      }
    };

    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleGlobalKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleGlobalKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, [menuVisible]);

  useEffect(() => {
    if (menuVisible && lastInteractionRef.current === 'keyboard' && menuItemRef.current) {
      menuItemRef.current.focus();
    }
  }, [menuVisible]);

  const displayName = props.nameTag;
  const nameInitials = initializeNameTag(displayName);

  return (
    <header>
      <div className="header" style={{ display: 'flex', alignItems: 'center', padding: '8px 24px', justifyContent: 'space-between' }}>
        <div tabIndex='0' style={{ display: 'flex', alignItems: 'center', flexShrink: 0, cursor: 'pointer' }} onClick={openRootPage} onKeyDown={handleKeyDownLogo}>
          <span style={{ color: '#0071BC', fontSize: '20px', fontWeight: '500' }}>CASA</span>
          <span style={{ margin: '0 8px', fontSize: '16px', fontWeight: '400' }}>-</span>
          <span style={{ color: "#5B616B", fontSize: '16px', fontWeight: '400' }}>Citation Analysis and Summary Assistant</span>
        </div>
        <div ref={containerRef} className="header-dropdown" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <InfoIcon type='nameIcon'>{nameInitials}</InfoIcon>
          <span style={{ padding: "16px 8px", fontSize: '16px', color: '#5B616B', fontWeight: '400' }}>{displayName}</span>
          <IconButton 
            onClick={() => {
              lastInteractionRef.current = 'mouse';
              toggleMenu();
            }} 
            onKeyDown={handleKeyDown} 
            style={{ padding: 0 }} 
            aria-label="Open user menu"
          >
            <ChevronDown style={{fill: '#5B616B', width: '24px', height: '24px'}} />
          </IconButton>
          
          {menuVisible && (
            <Paper 
              style={{ 
                position: 'absolute', 
                top: '100%', 
                left: 0, 
                width: '100%', 
                zIndex: 1,
                borderRadius: 0
              }}
            >
              <MenuItem
                ref={menuItemRef}
                onClick={handleLogout}
                onKeyDown={handleMenuItemKeyDown}
                sx={{
                  backgroundColor: '#FFFFFF',
                  '&:focus': {
                    backgroundColor: '#F2F9FF',
                  },
                  '&:hover': {
                    backgroundColor: '#F8F8F8',
                  },
                }}
              >
                Logout
              </MenuItem>
            </Paper>
          )}
        </div>
      </div>
      <div style={{height: '48px', backgroundColor: '#1E2E4E'}}></div>
    </header>
  );
};

export default Header;
