//Libraries
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const AllFeedback = () => {
    const rows = [
        { correct: 'No', user: 'Johnathan Jones', explanation: 'Here is an example of what a user might say about why this deficiency has been incorrectly identified' },
        { correct: 'Yes', user: 'Meredith Birney', explanation: '' },
      ];
      return (
        <div style={{paddingTop: '32px'}}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead sx={{borderTop: '1px solid #D6D7D9', borderBottom: '2px solid #D6D7D9'}}>
            <TableRow>
                <TableCell sx={{ fontWeight: 600, fontSize: '14px', color: '#242424' }}>Correct</TableCell>
                <TableCell sx={{ fontWeight: 600, fontSize: '14px', color: '#242424' }}>User</TableCell>
                <TableCell sx={{ fontWeight: 600, fontSize: '14px', color: '#242424' }}>Explanation</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: 400, fontSize: '14px', color: '#242424' }}>{row.correct}</TableCell>
                  <TableCell sx={{ fontWeight: 400, fontSize: '14px', color: '#242424' }}>{row.user}</TableCell>
                  <TableCell sx={{ fontWeight: 400, fontSize: '14px', color: '#242424' }}>{row.explanation}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
      );
};

export default AllFeedback;
