export default function FlagIcon({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill='none' xmlns="http://www.w3.org/2000/svg" style={style}>
        <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M0.677419 -0.00195312C0.857082 -0.00195312 1.02939 0.0568507 1.15643 0.161522C1.28347 0.266194 1.35484 0.408159 1.35484 0.556186V1.36363L2.77652 1.12921C4.39628 0.862492 6.07522 0.989706 7.60877 1.49535L7.79213 1.55563C9.06995 1.97672 10.4763 2.05536 11.8115 1.78037C12.0712 1.72688 12.3422 1.72285 12.6041 1.7686C12.866 1.81436 13.1117 1.90869 13.3227 2.04442C13.5337 2.18016 13.7043 2.35373 13.8217 2.55195C13.939 2.75017 14 2.96782 14 3.18837V8.67079C14 9.40605 13.3921 10.0475 12.5259 10.2261L12.3326 10.2656C10.596 10.6233 8.76676 10.521 7.10477 9.97312C5.81537 9.54821 4.40382 9.44143 3.04206 9.66577L1.35484 9.94409V15.4399C1.35484 15.5879 1.28347 15.7299 1.15643 15.8346C1.02939 15.9392 0.857082 15.998 0.677419 15.998C0.497757 15.998 0.325452 15.9392 0.198411 15.8346C0.0713707 15.7299 0 15.5879 0 15.4399V0.556186C0 0.408159 0.0713707 0.266194 0.198411 0.161522C0.325452 0.0568507 0.497757 -0.00195312 0.677419 -0.00195312ZM1.35484 8.80549L2.77652 8.57107C4.39628 8.3044 6.07521 8.43161 7.60877 8.93721C9.00608 9.3976 10.5439 9.48352 12.0039 9.18279L12.1981 9.1426C12.3257 9.11624 12.4391 9.0555 12.5201 8.97003C12.6011 8.88455 12.6451 8.77923 12.6452 8.67079V3.18837C12.6452 3.13747 12.6312 3.08722 12.6041 3.04145C12.5771 2.99568 12.5377 2.9556 12.489 2.92424C12.4404 2.89289 12.3836 2.87109 12.3232 2.86051C12.2628 2.84992 12.2002 2.85083 12.1403 2.86316C10.5288 3.1952 8.83136 3.10042 7.28903 2.59228L7.10477 2.53126C5.81537 2.10635 4.40382 1.99957 3.04206 2.22391L1.35484 2.50223V8.80549Z"/>
        </svg>
    );
}
