//Libraries
import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
//Components
import MyFeedback from './MyFeedback'
import AllFeedback from './AllFeedback';
//Styling
import './Feedback.css';
import '../../SurveyColorVariables.css'

function CustomTabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          className='custom-tab-panel2'
          style={{
            display: value === index ? 'flex' : 'none',
            flexDirection: 'column',
            paddingLeft: '0px'
          }}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography component="div">{children}</Typography>
            </Box>
          )}
        </div>
    );
}
 
function FeedbackBase() {
    const [value, setValue] = useState(0);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
            <div style={{ flex: 1, padding: '1px' }}>
                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                }}>  
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        borderBottom: 1, 
                        borderColor: 'divider'
                    }}>
                        <Tabs
                            value={value}
                            variant="scrollable"
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            sx={{
                                '.MuiTabs-indicator': {
                                    backgroundColor: '#0096F4',
                                    height: '6px'
                                },
                                '.MuiTab-root': {
                                    color: '#242424',
                                    fontWeight: 400,
                                    textTransform: 'none',
                                    '&.Mui-selected': {
                                        color: '#242424',
                                        fontWeight: 600,
                                    }
                                }
                            }}
                        >
                            <Tab label="My Feedback" />
                            <Tab label="All Feedback (2)" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                       <MyFeedback/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <AllFeedback/>
                    </CustomTabPanel>
                </Box>
            </div>
        </div>
    );
}

export default FeedbackBase;
