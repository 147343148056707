export default function SurveyIcon({ style }) {
    const defaultFill = "#5B616B"; 
    const fill = style?.fill || defaultFill; 

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill='none' xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fill={fill} d="M4.24188 -0.00127309C4.07903 0.0258411 3.93237 0.102465 3.8278 0.215064C3.72323 0.327663 3.66748 0.468992 3.6704 0.614085V1.22944H1.14408C0.581735 1.22944 0.155762 1.60235 0.155762 2.09464V15.075C0.155762 15.6288 0.581735 15.998 1.14408 15.998H13.1599C13.7222 15.998 14.1482 15.6251 14.1482 15.1329V2.09464C14.2185 1.60235 13.7883 1.22944 13.226 1.22944H10.6997V0.614085C10.6997 0.450882 10.6256 0.294363 10.4938 0.178961C10.362 0.0635591 10.1832 -0.00127309 9.99674 -0.00127309H4.37332C4.35131 -0.0021798 4.32926 -0.0021798 4.30725 -0.00127309C4.28523 -0.0021798 4.26389 -0.0021798 4.24188 -0.00127309ZM5.07625 1.22944H9.29381V2.46016H5.07625V1.22944ZM1.56162 2.46016H3.6704V3.07552C3.6704 3.23872 3.74445 3.39524 3.87628 3.51064C4.0081 3.62605 4.1869 3.69088 4.37332 3.69088H9.99674C10.1832 3.69088 10.362 3.62605 10.4938 3.51064C10.6256 3.39524 10.6997 3.23872 10.6997 3.07552V2.46016H12.8084V14.7673H1.56162V2.46016ZM2.96747 5.53695V7.99839H5.77918V5.53695H2.96747ZM3.6704 6.15231H5.07625V7.38303H3.6704V6.15231ZM7.18503 6.15231V7.38303H11.4026V6.15231H7.18503ZM2.96747 9.2291V11.6905H5.77918V9.2291H2.96747ZM7.18503 9.84446V11.0752H11.4026V9.84446H7.18503Z"/>
        </svg>
    );
}

  