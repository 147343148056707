import React from 'react';
import '../variable.css'

// Developer Notes:

// In order to call use...
// import InfoIcon from 'path/to/file';

// Required Props:
//     Type
//         1.surveyIcon
//         2.severityIcon
//         3.nameIcon

//     Variant(only required if Type is surveyIcon)
//         1.Standard
//         2.Complaint
//         3.InfectionControl


const InfoIcon = ({ icon, style: externalStyle, children, variant, type, fill,...rest }) => {
    const styleMappings = {
        surveyIcon: {
            Standard: {
                backgroundColor: fill? 'var(--standard-bg)': 'var(--nonfilled-color)',
                borderColor: fill? 'var(--standard-border)' : 'var(--nonfilled-color)',
                color: 'var(--standard-color)',
                label: 'S'
            },
            Complaint: {
                backgroundColor: fill? 'var(--complaint-bg)' : 'var(--nonfilled-color)',
                borderColor: fill? 'var(--complaint-border)' : 'var(--nonfilled-color)',
                color: 'var(--complaint-color)',
                label: 'C'
            },
            InfectionControl: {
                backgroundColor: fill? 'var(--infection-bg)' : 'var(--nonfilled-color)',
                borderColor: fill ? 'var(--infection-border)': 'var(--nonfilled-color)',
                color: 'var(--infection-color)',
                label: 'IC'
            }
        },
        severityIcon: {
            notCrit: {
                backgroundColor: 'var(--not-crit-bg)',
                borderColor: 'var(--not-crit-border)',
                color: 'var(--not-crit-color)'
            },
            crit: {
                backgroundColor: 'var(--crit-bg)',
                borderColor: 'var(--crit-border)',
                color: 'var(--crit-color)'
            }
        },
        nameIcon: {
            backgroundColor: 'var(--name-bg)',
            borderColor: 'var(--name-border)',
            color: 'var(--name-color)'
        }
    };

    const determineSeverityIcon = (text) => {
        const nonCrit = 'abcdefghi'.split('');
        return nonCrit.includes(text.toLowerCase()) ? 'notCrit' : 'crit';
    };

    let resolvedStyle = null;

    if (type === 'severityIcon') {
        const severityLevel = determineSeverityIcon(children);
        resolvedStyle = styleMappings[type]?.[severityLevel];
    } else if (type && variant) {
        resolvedStyle = styleMappings[type]?.[variant];
    } else if (type) {
        resolvedStyle = styleMappings[type];
    }

    if (!resolvedStyle) {
        resolvedStyle = {
            backgroundColor: 'lightgray',
            borderColor: 'gray',
            color: 'white'
        };
    }

    const baseStyle = {
        borderRadius: type === 'severityIcon' ? '6px' : '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: type === 'nameIcon' ? '40px' : '20px',
        height: type === 'nameIcon' ? '40px' : '20px',
        backgroundColor: resolvedStyle.backgroundColor,
        color: resolvedStyle.color,
        fontSize: type === 'nameIcon' ? '14px' : type === 'severityIcon' ? '16px' : '12px',
        fontWeight: type === 'severityIcon' ? 700 : 500,
        padding: type === 'severityIcon' ? '14px 11px' : '0',
        border: `1px solid ${resolvedStyle.borderColor}`
    };

    const combinedStyle = { ...baseStyle, ...externalStyle };

    let content = children;
    if (type === 'surveyIcon') {
        content = resolvedStyle?.label || children;
    }

    return (
        <div style={combinedStyle} {...rest}>
            {icon ? <i className={`fa ${icon}`}></i> : content}
        </div>
    );
};

export default InfoIcon;