export default function ChevronRight({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clip-path="url(#clip0_18233_10827)">
                <path fill={fill} d="M8.33334 5L7.15834 6.175L10.975 10L7.15834 13.825L8.33334 15L13.3333 10L8.33334 5Z"/>
            </g>
            <defs>
                <clipPath id="clip0_18233_10827">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>        
        </svg>
    );
}
  


  