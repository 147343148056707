export let API_KEY_MAPPINGS = {
    startDate: "start_date",
    endDate: "end_date",
    region: "region",
    state: "state",
    ccn: "facility_id",
    undercitationFlag: "sods.undercitation_flag",
    idr: "sods.idr",
    iidr: "sods.iidr",
    substandardQoc: "sods.substandard_qoc",
    sods: "number_of_deficiencies",
    facilityName: "facility_name",
    inspectionDate: "inspection_date",
    eventId:"eventid",
    deficiencyTag: "sods.deficiency_tag",
    inspectionText: "sods.inspection_text",
    scopeSeverity: "sods.scope_severity",
    surveyType: "sods.survey_type",
    surveyorId: "sods.surveyor_id",
    securityLevels: "sods.scope_severity_level",
    regulatoryGroup: "sods.regulatory_group",
    components: "sods.components",
    comments: "sods.comments",
    feedback: "sods.feedback",
    deficient_practice_statement: "deficient_practice_statement",
    survey_action: "survey_action",
    recipient:'recipient', 'extent':'extent',
    sample:'sample',
}
export let SURVEY_LIST_API_PROJECTIONS = [
    API_KEY_MAPPINGS.ccn, API_KEY_MAPPINGS.facilityName, API_KEY_MAPPINGS.inspectionDate, API_KEY_MAPPINGS.eventId,
    API_KEY_MAPPINGS.deficiencyTag, API_KEY_MAPPINGS.scopeSeverity, API_KEY_MAPPINGS.surveyType,
    API_KEY_MAPPINGS.regulatoryGroup, API_KEY_MAPPINGS.surveyorId, API_KEY_MAPPINGS.feedback, API_KEY_MAPPINGS.undercitationFlag
];
export let SURVEVY_DETAILS_API_PROJECTIONS = [
    API_KEY_MAPPINGS.inspectionDate, API_KEY_MAPPINGS.ccn, API_KEY_MAPPINGS.deficiencyTag,
    API_KEY_MAPPINGS.inspectionText, API_KEY_MAPPINGS.components, API_KEY_MAPPINGS.comments, API_KEY_MAPPINGS.feedback
]
export let SURVEY_LIST_SORT_MAPPINGS = [
    ["byDate", {displayLabel: 'Date', apiKey: API_KEY_MAPPINGS.inspectionDate}],
    ["byScopeSeverity", {displayLabel: 'Scope/Severity', apiKey: API_KEY_MAPPINGS.scopeSeverity}],
    ["byCcn", {displayLabel: 'CCN', apiKey: API_KEY_MAPPINGS.ccn}],
    ["bysods", {displayLabel: 'Number of Deficiencies', apiKey: API_KEY_MAPPINGS.sods}]
]
export let PRINCIPLES_OF_DOCUMENTATION = {
    "deficient_practice_statement": {displayLabel: 'Deficient Practice', apiKey: API_KEY_MAPPINGS.deficient_practice_statement},
    "survey_action": {displayLabel: 'Survey Action', apiKey: API_KEY_MAPPINGS.survey_action},
    "recipient": {displayLabel: 'Recipient', apiKey: API_KEY_MAPPINGS.recipient},
    "extent": {displayLabel: 'Extent', apiKey: API_KEY_MAPPINGS.extent},
    "sample": {displayLabel: 'Sample', apiKey: API_KEY_MAPPINGS.sample},
}

export const DefaultFilter = {
    filter: {},
    projection: SURVEY_LIST_API_PROJECTIONS,
    sort: {
        field: API_KEY_MAPPINGS.inspectionDate,
        order: "desc"
    },
    start: 0,
    limit: 10
}

export let PageTitles = {
    "/dashboards": "Dashboards",
    "/surveys": "Surveys",
    "/status" : "Status"
}