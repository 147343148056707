export default function SeverityIndicator({ style, hidden }) {
    const defaultFill = "#5B616B";
    const fill = style?.fill || defaultFill;

    return (
        <svg hidden={hidden} style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M6.45198 0.979046C7.11609 -0.326349 8.88391 -0.326349 9.54802 0.979046L9.54809 0.979181L15.7899 13.2555C15.9341 13.5392 16.0063 13.8575 15.9996 14.1796C15.9928 14.5016 15.9073 14.8162 15.7513 15.0928C15.5954 15.3693 15.3744 15.5983 15.1099 15.7573C14.8453 15.9163 14.5464 15.9999 14.2423 16H1.75773C1.45358 15.9999 1.15465 15.9163 0.890147 15.7573C0.625643 15.5983 0.404619 15.3693 0.248674 15.0928C0.092728 14.8162 0.0071952 14.5016 0.000434473 14.1796C-0.00632629 13.8575 0.0659161 13.5392 0.210102 13.2556L6.45191 0.979181L6.45198 0.979046ZM7.48378 1.56777L7.48385 1.56763C7.70539 1.13241 8.29461 1.13241 8.51615 1.56763C8.51617 1.56768 8.51619 1.56772 8.51622 1.56777L14.7579 13.844C14.806 13.9385 14.8301 14.0446 14.8278 14.152C14.8256 14.2593 14.7971 14.3642 14.7451 14.4564C14.6931 14.5486 14.6194 14.6249 14.5313 14.6779C14.4431 14.7309 14.3435 14.7587 14.2422 14.7588C14.2421 14.7588 14.2421 14.7588 14.2421 14.7588H1.75794M7.48378 1.56777L1.24206 13.844C1.24206 13.844 1.24207 13.844 1.24206 13.844C1.19401 13.9385 1.16992 14.0446 1.17218 14.152C1.17443 14.2593 1.20294 14.3642 1.25492 14.4564C1.3069 14.5486 1.38058 14.6249 1.46875 14.6779C1.55688 14.7309 1.65649 14.7587 1.75784 14.7588"/>
            
            <path fill={defaultFill} fillRule="evenodd" clipRule="evenodd" d="M7.9964 5.00001C8.04492 4.99967 8.09397 5.00645 8.14105 5.02076C8.18771 5.03494 8.23483 5.05725 8.2784 5.09C8.32057 5.1217 8.3679 5.16959 8.40571 5.24221C8.44556 5.31874 8.47746 5.43 8.46915 5.56281L8.46908 5.56395L8.20066 9.70788C8.1899 9.87391 8.10334 9.99999 8.00011 10C7.89687 10 7.8103 9.87395 7.79953 9.70793L7.53016 5.55485C7.52357 5.42445 7.55548 5.31601 7.59458 5.24129C7.63182 5.17011 7.67818 5.12284 7.71965 5.09133C7.76243 5.05882 7.80865 5.0365 7.85442 5.0221C7.9006 5.00758 7.94873 5.00035 7.9964 5.00001Z"/>
            <path fill={defaultFill} d="M7.99989 13C7.90681 13 7.81583 12.9707 7.73844 12.9157C7.66105 12.8608 7.60074 12.7827 7.56512 12.6913C7.5295 12.6 7.52018 12.4994 7.53834 12.4025C7.5565 12.3055 7.60132 12.2164 7.66713 12.1464C7.73294 12.0765 7.81679 12.0289 7.90808 12.0096C7.99936 11.9903 8.09398 12.0002 8.17997 12.0381C8.26596 12.0759 8.33946 12.14 8.39117 12.2222C8.44287 12.3044 8.47047 12.4011 8.47047 12.5C8.47047 12.6326 8.42089 12.7598 8.33264 12.8536C8.24439 12.9473 8.12469 13 7.99989 13Z"/>
        </svg>
    );
}
