export default function ChevronDown({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clip-path="url(#clip0_14037_9437)">
            <path fill={fill} d="M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z"/>
            </g>
            <defs>
            <clipPath id="clip0_14037_9437">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    );
}