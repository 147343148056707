export default function MenuExpandIcon({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default
    
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fill={fill} d="M13.1558 13.998L13.1558 -0.00195321L14.1558 -0.00195312L14.1558 13.998L13.1558 13.998Z"/>
            <path fill={fill} d="M0.155763 6.99788C0.155763 6.70973 0.371499 6.47614 0.637622 6.47614L9.50528 6.47614L7.11548 3.88849C6.9273 3.68473 6.9273 3.35438 7.11548 3.15062C7.30365 2.94686 7.60875 2.94686 7.79693 3.15062L11.0093 6.62895C11.2528 6.99789 11.1498 7.21469 11.009 7.36716L7.79693 10.8452C7.60875 11.0489 7.30365 11.0489 7.11548 10.8452C6.9273 10.6414 6.9273 10.311 7.11548 10.1073L9.50528 7.51963L0.637621 7.51963C0.371499 7.51963 0.155763 7.28604 0.155763 6.99788Z"/>
        </svg>
    );
}
    
    
