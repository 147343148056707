import React, { useEffect } from 'react';
import { TextAnnotator } from 'react-text-annotate';
import { useSurvey } from '../../SurveyContext';

const FullReportInfo = ({ sections, annotations, handleSelection }) => {
    const { updateSections, sectionsRef } = useSurvey(); // Get updateSections and sectionsRef from context

      useEffect(() => {
        // Update sections in context when they are processed
        updateSections(sections);
    }, [sections, updateSections]);

    useEffect(() => {
        console.log('sectionsRef.current:', sectionsRef.current); // Check the sections refs
    }, [sections]);


    const validateAnnotations = (annotations, sectionIndex) => {
        return annotations.filter(annotation => 
            parseInt(annotation.section) === sectionIndex &&
            annotations.some(ann => ann.text === annotation.text && ann.start === annotation.start && ann.end === annotation.end)
        );
    };

    return (
        <>
            {sections && sections.length > 0 ? (
                sections.map((section, idx) => {
                    const filteredAnnotations = validateAnnotations(annotations, idx);
                    const isLastIndex = idx === sections.length - 1;

                    return (
                        <React.Fragment key={idx}>
                            <div className={`app-${idx}`}  ref={(el) => (sectionsRef.current[idx] = el)} style={{ padding: 0 }}>
                                <TextAnnotator
                                    style={{ lineHeight: 1.5, padding: isLastIndex ? '0' : '8px 0', color: '#242424', fontSize: '14px', fontWeight: 400 }}
                                    content={section.content}
                                    value={filteredAnnotations}
                                    getSpan={span => ({
                                        ...span,
                                        tag: span.text,
                                    })}
                                    editable={false}
                                />
                            </div>
                        </React.Fragment>
                    );
                })
            ) : null}
        </>
    );
};

export default FullReportInfo;
