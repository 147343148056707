//Libraries
import React, { useState } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
//Components
import Button from '../../../../base-components/Button'

const MyFeedback = () => {
    const [feedbackValue, setFeedbackValue] = useState('');
    const [userNotes, setUserFeedback] = useState('');

    const handleRadioChange = (event) => {
        setFeedbackValue(event.target.value);
    };

    const handleResetFeedback = () => {
        setFeedbackValue('');
    }

    const handleSaveUserFeedback = () => {
        //Do the save part here.
        setUserFeedback();
    }

    const handleSetUserFeedback = () => {
        setUserFeedback();
    }
    

    return (
        <>
            <div style={{fontWeight: 600, fontSize: '14px', color: '#242424', paddingTop: '32px'}}>Is this deficiency correctly identified as under-cited for severity?</div>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={feedbackValue}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={<Typography style={{ fontSize: 16, fontWeight: 400 }}>Yes</Typography>}
                    />
                    <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label={<Typography style={{ fontSize: 16, fontWeight: 400 }}>No</Typography>}
                    />
                </RadioGroup>
            </FormControl>
            {feedbackValue === 'no' && (
                <>
                    <div style={{fontWeight: 600, fontSize: '14px', color: '#242424'}}>Explanation</div>

                    <textarea
                        style={{
                            border: '1px solid #5B616B',
                            width: '100%',
                            resize: 'vertical',
                            minHeight: '40px',
                            marginTop: '16px', // Added margin for spacing
                        }}
                        placeholder="Type"
                    />
                    

                </>

            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '24px', marginTop: '16px' }}>
                <Button disabled={feedbackValue===''} variant='Primary' outline onClick={handleResetFeedback}>Clear</Button>
                <Button disabled={feedbackValue===''} variant='Primary' onClick={handleSaveUserFeedback} >Save</Button>
            </div>
            
        </>
    );
};

export default MyFeedback;
