export default function EllipsisExpandIcon({ style }) {
    const defaultFill = "#0071BC";
    const fill = style?.fill || defaultFill;

    return (
        <svg width="16" height="16" style={style} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3" cy="8" r="1.5" fill={fill} />
            <circle cx="8" cy="8" r="1.5" fill={fill} />
            <circle cx="13" cy="8" r="1.5" fill={fill} />
        </svg>
    );
}