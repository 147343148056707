export default function FilterIcon({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.0647408 0.344113C0.16979 0.132146 0.385913 -0.00195312 0.622482 -0.00195312H15.3775C15.6141 -0.00195312 15.8302 0.132146 15.9353 0.344113C16.0403 0.55608 16.0161 0.809273 15.8729 0.997522L10.098 8.58506V15.3756C10.098 15.5961 9.98128 15.8002 9.79118 15.9121C9.60109 16.0239 9.36601 16.0268 9.17321 15.9197L6.2222 14.2803C6.02458 14.1705 5.90202 13.9622 5.90202 13.7361V8.58506L0.127151 0.997522C-0.0161245 0.809273 -0.0403082 0.55608 0.0647408 0.344113ZM1.87851 1.243L7.01983 7.99812C7.10231 8.1065 7.14698 8.23893 7.14698 8.37512V13.3699L8.85303 14.3177V8.37512C8.85303 8.23893 8.89769 8.1065 8.98018 7.99812L14.1215 1.243H1.87851Z" />
        </svg>
    );
}

  
  