import React, { useEffect, useState } from 'react';

const ApiTesting = () => {
    const [lambdaResponse, setLambdaResponse] = useState(null);

    useEffect(() => {
        fetch(`/api/status`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setLambdaResponse(data)
            })
            .catch(error => {
                console.error('Failed to fetch from Lambda:', error);
            });
    }, []);

    const formatUptime =(seconds) => {
        if(seconds){
            const days = Math.floor(seconds / 86400);
            const hours = Math.floor((seconds % 86400) / 3600);
            const minutes = Math.floor((seconds % 3600) / 60); 
            const sec = Math.floor(seconds % 60);
        return `${days}d ${hours}h ${minutes}m ${sec}s`; 
        }
    }
    
    return (
        <div style={{padding: '24px'}}>
                {lambdaResponse && 
                             <ul>
                                <li>CASA Version: {lambdaResponse.version}</li>
                                <li>Environment: {lambdaResponse.environment}</li>
                                <li>Status: {lambdaResponse.status}</li>
                                <li>Uptime: {formatUptime(lambdaResponse.uptime)}</li>
                                <li>React Version: {lambdaResponse.React}</li>
                                <li>Node Version: {lambdaResponse.Node}</li>
                            </ul>
                }
       
        </div>
    );
};

export default ApiTesting;
