  export default function MenuCollapseIcon({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default
  
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fill={fill} d="M1 -0.00195308L0.999999 13.998H0L6.1196e-07 -0.00195312L1 -0.00195308Z"/>
            <path fill={fill} d="M14 6.99821C14 7.28636 13.7843 7.51996 13.5181 7.51996L4.65048 7.51996L7.04029 10.1076C7.22846 10.3114 7.22846 10.6417 7.04029 10.8455C6.85211 11.0492 6.54701 11.0492 6.35884 10.8455L3.14645 7.36714C2.90301 6.99821 3.00596 6.7814 3.14677 6.62893L6.35884 3.15094C6.54701 2.94719 6.85211 2.94719 7.04029 3.15094C7.22846 3.3547 7.22846 3.68505 7.04029 3.88881L4.65048 6.47646L13.5181 6.47646C13.7843 6.47646 14 6.71005 14 6.99821Z"/>      
        </svg>
    );
  }

