import React, {useEffect, useState} from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, InputLabel, MenuItem, FormControl, Select, IconButton, Grid, Paper, Box, Chip, TextField} from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomExpandIcon from './ExpandIcon'
import { mockStates, mockRegions, mockDefTags, mockSevScopes, mockSevType, mockSevLevels, mockRegGroups, mockFacilityNames, mockFacilityCCNs, mockCheckboxes, mockSeverityCheckboxes } from '../TestingData/MockFilterData';

import FlagIcon from '../../../assets/icons/FlagIcon';
import AttributesIcon from '../../../assets/icons/AttributesIcon';
import BuildingIcon from '../../../assets/icons/BuildingIcon';
import DateIcon from '../../../assets/icons/DateIcon';
import LocationIcon from '../../../assets/icons/LocationIcon';
import SurveyIcon from '../../../assets/icons/SurveyIcon';
import ChevronDown from '../../../assets/icons/chevrons/ChevronDown';
import ChevronRightDup from '../../../assets/icons/ChevronRightDup';
// import { CustomExpandIcon } from './ExpandIcon';
import {filterSurveys, getFilterOptions, triggerFilterMappings} from '../../../common/api';

import './Filters.css'
import Button from "../../../base-components/Button"
import {useSurvey} from "../SurveyContext";
import {FilterProps} from "../../../common/types"
import {API_KEY_MAPPINGS} from "../../../common/constants";
import dayjs from "dayjs";
import {isEmptyDate} from "../../../common/utils";

const selectFieldStyles = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#5B616B",
    borderRadius: "2px"
  },
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#F1F1F1',
  padding: '5px',
  borderTop: '1px solid #D6D7D9',
  borderBottom: '1px solid #D6D7D9',
}));

const Icon = styled(props => (
  <div {...props}>
    <div className="n">n</div> {/* Icon for collapsed state */}
    <div className="y">y</div> {/* Icon for expanded state */}
  </div>
))`
  & > .y {
    display: block;  /* Show 'y' (expanded) by default */
  }
  & > .n {
    display: none;   /* Hide 'n' (collapsed) by default */
  }

  /* When the accordion is expanded, toggle visibility */
  .Mui-expanded & > .n {
    display: block;  /* Show 'n' (collapsed) when accordion is expanded */
  }
  .Mui-expanded & > .y {
    display: none;   /* Hide 'y' (expanded) when accordion is expanded */
  }
`;

const Filters = (props: FilterProps) => {
  let { handleToggle } = props;
  const [facilityNames, setFacilityNames] = useState([]);
  const [ccns, setCCNs] = useState([]);
  const [deficiencyTags, setDeficiencyTags] = useState([]);
  const [securityLevels, setSecurityLevels] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [regulatoryGroups, setRegulatoryGroups] = useState([]);
  const [surveyTypes, setSurveyTypes] = useState([]);

  const stateRegionMapping = {
    "SEATTLE": ["AK", "ID", "OR", "WA"], "SAN FRANCISCO": ["AZ", "CA", "HI", "NV"], "PHILADELPHIA": ["DE", "DC", "MD", "PA", "WV", "VA"],
    "NEW YORK": ["NY", "NJ", "PR"], "KANSAS CITY": ["IA", "KS", "MO", "NE"], "DENVER": ["CO", "ND", "MT", "SD", "UT", "WY"],
    "DALLAS": ["AR", "LA", "NM", "OK", "TX"], "CHICAGO": ["IL", "IN", "MI", "MN", "OH", "WI"], "BOSTON": ["CT", "ME", "MA", "NH", "RI", "VT"],
    "ATLANTA": ["AL", "FL", "GA", "KY", "MS", "NC", "SC", "TN"]}

  const [regionStateMappings, setRegionStateMappings] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const [showAllSurveysWithDefFlagForSev, setShowAllSurveysWithDefFlagForSev] = useState('');
  const [idrEnabled, setIdrEnabled] = useState('');
  const [iidrEnabled, setIidrEnabled] = useState('');
  const [substandardQocEnabled, setSubstandardQocEnabled] = useState('');
  const [startDate, setStartDate] =  React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [dateError, setDateError] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDTs, setSelectedDTs] = useState([]);
  const [selectedSeverityLevels, setSelectedSeverityLevels] = useState([]);
  const [selectedScopeSeverity, setSelectedScopeSeverity] = useState([]);
  const [selectedRegulatoryGroups, setSelectedRegulatoryGroups] = useState([]);
  const [selectedFacilityNames, setSelectedFacilityNames] = useState([]);
  const [selectedCCNs, setSelectedCCNs] = useState([]);
  const [selectedSurveyTypes, setSelectedSurveyTypes] = useState([]);

  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  const [isFiltersApplied, setIsFiltersApplied] = useState(true);
  const [isFilterCleared, setIsFilterCleared] = useState(false);
  const [labeledRegions, setLabeledRegions] = useState({});

  const { updateSurveyDefList, selectedFilter,
    setSelectedFilter, updateSelectedDeficiency,
    updateSurveyCount, surveysLoading, updateSurveysLoading,
    scrollSurveyListToTop, updateScrollSurveyListToTop
} = useSurvey();
  const handleChange = (event, value, entity) => {
    entity(value); // Update the state with the selected value(s)
  };
  // const handleRegionChange = (event, values, entity) => {
  //   let matchedStates = values.map(region => (region.option)).map(selectedRegion => regionStateMappings[selectedRegion])
  //   setAvailableStates(matchedStates.flat());
  //   entity(values);
  // };


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value, entity) => {
    entity(value);
  };

  const processSurveyTypes = (surveyTypes)=> {
    setSurveyTypes([...new Set(surveyTypes.flat())]);
  }
  const processFacilityNames = (facilityNames)=> {
    setFacilityNames(facilityNames.map(facilityName => {
      return {option: facilityName, value: facilityName}
    }));
  }
  const fetchFieldValues = async (fieldName, setFieldValues, processValuesCallback) => {
    let requestParams = {
      "group": fieldName,
      "sort_by": "asc"
    };

    let filterOptions = await getFilterOptions(requestParams);
    if(processValuesCallback)
      processValuesCallback(filterOptions)
    else {
      setFieldValues(filterOptions)
    }
  };

  useEffect(() => {
    triggerFilterMappings().then(mappings => {
      setRegionStateMappings(mappings);
    });
    fetchFieldValues(API_KEY_MAPPINGS.state, setAvailableStates);
    fetchFieldValues(API_KEY_MAPPINGS.facilityName, setFacilityNames, processFacilityNames);
    fetchFieldValues(API_KEY_MAPPINGS.ccn, setCCNs);
    fetchFieldValues(API_KEY_MAPPINGS.deficiencyTag, setDeficiencyTags);
    fetchFieldValues(API_KEY_MAPPINGS.securityLevels, setSecurityLevels);
    fetchFieldValues(API_KEY_MAPPINGS.scopeSeverity, setScopes);
    fetchFieldValues(API_KEY_MAPPINGS.regulatoryGroup, setRegulatoryGroups);
    fetchFieldValues(API_KEY_MAPPINGS.surveyType, setSurveyTypes, processSurveyTypes);
    setLabeledRegions(generateRegionLabels(mockRegions));
  }, [])
  const isFiltersUpdated = () => {
    //TODO: Add startDate and EndDate checks in the below condition
    // startDate !== '' && endDate !== '' &&
    return (!showAllSurveysWithDefFlagForSev && isEmptyDate(startDate) && isEmptyDate(endDate) &&
    selectedRegions.length === 0 && selectedStates.length === 0 && selectedFacilityNames.length === 0 &&
    selectedCCNs.length === 0 && !idrEnabled && !iidrEnabled && !substandardQocEnabled &&
    selectedDTs.length === 0 &&
    selectedSeverityLevels.length === 0 && selectedScopeSeverity.length === 0 &&
    selectedRegulatoryGroups.length === 0 &&
    selectedSurveyTypes.length === 0);
  }
  useEffect(() => {
    if(isFiltersUpdated()) {
      setIsFiltersChanged(true);
    } else {
      setIsFiltersChanged(false);
    }
    setIsFiltersApplied(false);
  }, [
    showAllSurveysWithDefFlagForSev,
    startDate,
    endDate,
    selectedRegions,
    selectedStates,
    selectedFacilityNames,
    selectedCCNs,
    idrEnabled,
    iidrEnabled,
    substandardQocEnabled,
    selectedDTs,
    selectedSeverityLevels,
    selectedScopeSeverity,
    selectedRegulatoryGroups,
    selectedSurveyTypes
  ])
  useEffect(() => {
    if(isFilterCleared) {
      applyFilters();
    }
  }, [isFilterCleared])
  useEffect(() => {
    let labeledMockRegions = generateRegionLabels(mockRegions)
    //As the filterSelectedOptions field is not working automatically to remove the selected value from the
    // dropdown list for region field, the below code will take care of that.
    //This will just iterate the selected regions and remove those from the dropdown list.
    setLabeledRegions(labeledMockRegions.filter(region => !selectedRegions.find(selectedRegion => selectedRegion.option === region.option)))
  }, [selectedRegions])
  useEffect(() => {
    //Making default state of the Apply button to be disabled.
    setIsFiltersApplied(true);
  }, [])
  // State to manage expanded/collapsed for each accordion
  const [expandedAccordions, setExpandedAccordions] = useState({});

  const getFlags = (flags) => {
    let result = {};
    Object.keys(flags).forEach(key => {
      if (flags[key] !== '') {
        result[key] = flags[key]
      }
    });
    return result;
  }

  // Toggle function for accordion
  const applyFilters = async (event) => {
    selectedFilter.start = 0;
    selectedFilter.limit = 10;

    // Use the helper function to prepare flags
    let additionalFlags = getFlags({
      undercitationFlag: showAllSurveysWithDefFlagForSev,
      idrEnabled: idrEnabled,
      iidrEnabled: iidrEnabled,
      substandardQocEnabled: substandardQocEnabled
    });

    // Constructing API parameters
    let apiParams = {
      startDate: startDate,
      endDate: endDate,
      selectedRegions: selectedRegions.map(region => region.option),
      selectedStates,
      selectedFacilityNames: selectedFacilityNames.map(facilityName => facilityName.value),
      selectedCCNs,
      selectedDTs,
      selectedSeverityLevels,
      selectedScopeSeverity,
      selectedRegulatoryGroups,
      selectedSurveyTypes,
      ...additionalFlags
    };
    updateSurveysLoading(true);
    updateScrollSurveyListToTop(true);
    let surveysResponse = await filterSurveys(selectedFilter, apiParams);
    updateSelectedDeficiency(undefined);
    setSelectedFilter(selectedFilter);
    await updateSurveyDefList(surveysResponse.surveys);
    await updateSurveyCount(surveysResponse.count);
    updateSurveysLoading(false);
    setIsFiltersApplied(true);
    setIsFilterCleared(false);
    updateScrollSurveyListToTop(false);
    handleToggle();
  };

  const resetAndApplyFilters = async (event) => {
    setShowAllSurveysWithDefFlagForSev("");
    setStartDate(null);
    setEndDate(null);
    setSelectedRegions([]);
    setSelectedStates([]);
    setSelectedFacilityNames([]);
    setSelectedCCNs([]);
    setIdrEnabled("");
    setIidrEnabled("");
    setSubstandardQocEnabled("");
    setSelectedDTs([]);
    setSelectedSeverityLevels([])
    setSelectedScopeSeverity([]);
    setSelectedRegulatoryGroups([]);
    setSelectedSurveyTypes([]);
    setIsFilterCleared(true);
  };

  // Toggle function for accordion
  const handleCollapseToggle = (panel) => (event, isExpanded) => {
    setExpandedAccordions((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const generateRegionLabels = (regions) => {
    return regions.map((region, index) => {
      const paddedIndex = String(index + 1).padStart(2, '0'); // Pad the index with a leading zero if necessary
      const label = `${paddedIndex}-${region.option.charAt(0).toUpperCase() + region.option.slice(1).toLowerCase()}`; // Create label in the desired format
      return { ...region, label }; // Return the region with the new label attached
    });
  };

  const toTitleCase = (str) => {
    const recognizedAcronyms = ["SNF", "LLC", "LTC", "INC"];

    return str.replace(/\b\w+\b/g, (txt) => {
      if (recognizedAcronyms.includes(txt)) {
        return txt;
      }

      if (txt.includes("'")) {
        const parts = txt.split("'");
        return parts[0].charAt(0).toUpperCase() + parts[0].substr(1).toLowerCase() + "'" + parts[1].toLowerCase();
      }

      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const generateFacilityLabels = (facilityNames) => {
    return facilityNames.map((facilityName) => {
      const label = toTitleCase(facilityName.option);
      return { ...facilityName, label };
    });
  }

  const generateSevTypeLabels = (sevTypes) => {
    return sevTypes.map((sevType) => {
      const label = toTitleCase(sevType.option);
      return { ...sevType, label };

    })
  }

  const summaryStyles = {
    fontFamily: 'Rubik, sans-serif',
    color: '#5B616B',
    fontWeight: 800,
    fontSize: '16px',
    borderBottom: '1px solid #D6D7D9',
    '&.Mui-expanded': {
      color: '#205493',
    },
  };

  const iconButtonStyles = {
    '&:hover': {
      backgroundColor: '#F8F8F8',
      color: '#0071BC'
    },
    padding: '0px',
    color: '#0071BC'
  };


  const accordionStyles = {
    border: 'none',
  };

  return (
      <div id='panel_scrollbar' className='all_scrollbars' style={{  maxHeight: 'calc(100vh - 133px)', overflowY: 'auto'}}>
        <Grid container spacing={2}>

          <Grid item xs={12} md={6}>
            <Box sx={{ p: 0.5, mb: 2 }}>
              <Accordion
                  elevation={0}
                  sx={accordionStyles}
                  expanded={!!expandedAccordions['panel1']}
                  onChange={handleCollapseToggle('panel1')}
              >
                <AccordionSummary

                    expandIcon={
                      <CustomExpandIcon
                      expanded={!!expandedAccordions['panel1']}

                      />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      ...summaryStyles,
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'none !important',

                      },
                    }}

                >

                  <FlagIcon style={{fill: !!expandedAccordions['panel1'] ? '#205493' : undefined, marginRight: '5px', marginBottom: '0px',marginTop: '4px'}}/>
                  Severity Flag
                </AccordionSummary>
                <AccordionDetails>
                  <Grid sx={{ marginTop: "10px"}} container spacing={2} style={{paddingLeft: '16px', paddingRight:'16px'}}>
                  <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                  <p>Flagged for Severity</p>
                  <Autocomplete
                      sx={selectFieldStyles}
                      popupIcon={<ChevronDown/>}
                      disableClearable
                      id="severity-autocomplete"
                      options={mockSeverityCheckboxes}
                      value={mockSeverityCheckboxes.find(option => option.value === showAllSurveysWithDefFlagForSev)}
                      getOptionLabel={(option) => option.option}
                      onChange={(event, value) => setShowAllSurveysWithDefFlagForSev(value ? value.value : '')}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                          />
                      )}
                  />
                </FormControl>
                </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ p: 0.5, mb: 2 }}>
              <Accordion
                  elevation={0}
                  sx={accordionStyles}
                  expanded={!!expandedAccordions['panel2']}
                  onChange={handleCollapseToggle('panel2')}
              >
                <AccordionSummary
                  expandIcon={
                    <CustomExpandIcon
                    expanded={!!expandedAccordions['panel2']}

                    />
                  }
                    aria-controls="panel2-content"
                    id="panel2-header"
                    sx={{
                      ...summaryStyles,
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'none !important'
                      },
                    }}
                >
                  <LocationIcon style={{fill: !!expandedAccordions['panel2'] ? '#205493' : undefined, marginRight: '5px', marginBottom: '0px',marginTop: '4px'}}/>
                  Location
                </AccordionSummary>
                <AccordionDetails>
                  <Grid sx={{ marginTop: "10px" }} container spacing={2} style={{paddingLeft: '16px', paddingRight:'16px'}}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <p>Region</p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="tags-outlined"
                            options={labeledRegions}
                            value={selectedRegions}
                            getOptionLabel={(option) => option.label}
                            // defaultValue={[regions[1]]}
                            filterSelectedOptions
                            // onChange={(event, value) => handleRegionChange(event, value, setSelectedRegions)} // prints the selected value
                            onChange={(event, value) => handleChange(event, value, setSelectedRegions)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedRegions.length === 0 ? "Select" : ""}
                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <p>State or Territory</p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="tags-outlined"
                            options={availableStates}
                            value={selectedStates}
                            noOptionsText="Select Regions"
                            // getOptionLabel={(option) => option.option}
                            // defaultValue={[states[1]]}
                            filterSelectedOptions
                            onChange={(event, value) => handleChange(event, value, setSelectedStates)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedStates.length === 0 ? "Select" : ""}

                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ p: 0.5, mb: 2 }}>
              <Accordion
                  elevation={0}
                  sx={accordionStyles}
                  expanded={!!expandedAccordions['panel3']}
                  onChange={handleCollapseToggle('panel3')}
              >
                <AccordionSummary
                    expandIcon={
                      <CustomExpandIcon
                      expanded={!!expandedAccordions['panel3']}

                      />
                    }
                    aria-controls="panel3-content"
                    id="panel3-header"
                    sx={{
                      ...summaryStyles,
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'none !important'
                      },
                    }}
                  >
                  <AttributesIcon style={{fill: !!expandedAccordions['panel3'] ? '#205493' : undefined, marginRight: '5px', marginBottom: '0px',marginTop: '4px'}}/>
                  Deficiency Attributes
                </AccordionSummary>
                <AccordionDetails>
                <Grid sx={{ marginTop: "10px" }} container spacing={2} style={{paddingLeft: '16px', paddingRight:'16px'}}>
                  <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                  <p>IDR</p>
                  <Autocomplete
                      sx={selectFieldStyles}
                      popupIcon={<ChevronDown/>}
                      disableClearable
                      id="severity-autocomplete"
                      options={mockCheckboxes}
                      value={mockCheckboxes.find(option => option.value === idrEnabled)}
                      getOptionLabel={(option) => option.option}
                      onChange={(event, value) => setIdrEnabled(value ? value.value : '')}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                          />
                      )}
                  />
                </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                  <p>IIDR</p>
                  <Autocomplete
                      sx={selectFieldStyles}
                      popupIcon={<ChevronDown/>}
                      disableClearable
                      id="severity-autocomplete"
                      options={mockCheckboxes}
                      value={mockCheckboxes.find(option => option.value === iidrEnabled)}
                      getOptionLabel={(option) => option.option}
                      onChange={(event, value) => setIidrEnabled(value ? value.value : '')}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                          />
                      )}
                  />
                </FormControl>
                </Grid>
                  </Grid>
                  <Grid sx={{ marginTop: "10px" }} container spacing={2} style={{paddingLeft: '16px', paddingRight:'16px'}}>
                  <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                  <p>Substandard QOC</p>
                  <Autocomplete
                      sx={selectFieldStyles}
                      popupIcon={<ChevronDown/>}
                      disableClearable
                      id="severity-autocomplete"
                      options={mockCheckboxes}
                      value={mockCheckboxes.find(option => option.value === substandardQocEnabled)}
                      getOptionLabel={(option) => option.option}
                      onChange={(event, value) => setSubstandardQocEnabled(value ? value.value : '')}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                          />
                      )}
                  />
                </FormControl>
                </Grid>
                  </Grid>

                  <Grid sx={{ marginTop: "10px" }} container spacing={2} style={{paddingLeft: '16px', paddingRight:'16px'}}>
                    <Grid item xs={6} >
                      <FormControl fullWidth variant="outlined">
                        <p>Deficiency Tag</p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="tags-outlined"
                            options={deficiencyTags}
                            value={selectedDTs}
                            getOptionLabel={(option) => `F-${option}`}
                            filterSelectedOptions
                            onChange={(event, value) => handleChange(event, value, setSelectedDTs)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedDTs.length === 0 ? "Select" : ""}

                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} >
                      <FormControl fullWidth variant="outlined">
                        <p>Severity Level </p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="tags-outlined"
                            options={securityLevels}
                            value={selectedSeverityLevels}
                            // getOptionLabel={(option) => option.option}
                            // defaultValue={[severityLevels[1]]}
                            filterSelectedOptions
                            onChange={(event, value) => handleChange(event, value, setSelectedSeverityLevels)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedSeverityLevels.length === 0 ? "Select" : ""}

                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} >
                      <FormControl fullWidth variant="outlined">
                        <p>Scope/Severity</p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="tags-outlined"
                            options={scopes}
                            value={selectedScopeSeverity}
                            // getOptionLabel={(option) => option.option}
                            // defaultValue={[scopeSeverity[1]]}
                            filterSelectedOptions
                            onChange={(event, value) => handleChange(event, value, setSelectedScopeSeverity)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedScopeSeverity.length === 0 ? "Select" : ""}

                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} >
                      <FormControl fullWidth variant="outlined">
                        <p>Regulatory Group</p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="tags-outlined"
                            options={regulatoryGroups}
                            value={selectedRegulatoryGroups}
                            // getOptionLabel={(option) => option.option}
                            // defaultValue={[regulatoryGroups[1]]}
                            filterSelectedOptions
                            onChange={(event, value) => handleChange(event, value, setSelectedRegulatoryGroups)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedRegulatoryGroups.length === 0 ? "Select" : ""}
                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>



          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ p: 0.5, mb: 2 }}>
              <Accordion
                  elevation={0}
                  sx={accordionStyles}
                  expanded={!!expandedAccordions['panel5']}
                  onChange={handleCollapseToggle('panel5')}
              >
                <AccordionSummary
                    expandIcon={
                      <CustomExpandIcon
                      expanded={!!expandedAccordions['panel5']}

                      />
                    }
                    aria-controls="panel5-content"
                    id="panel5-header"
                    sx={{
                      ...summaryStyles,
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'none !important'
                      },
                    }}
                >
                  <DateIcon style={{fill: !!expandedAccordions['panel5'] ? '#205493' : undefined, marginRight: '5px', marginBottom: '0px',marginTop: '4px'}}/>
                  Date
                </AccordionSummary>
                <AccordionDetails>
                  <Grid sx={{ marginTop: "10px" }} container spacing={2} style={{paddingLeft: '16px', paddingRight:'16px'}}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <p>Start Date</p>
                          <DatePicker
                              shrink={true}
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                              minDate={dayjs('2015-01-01')}
                              maxDate={dayjs()}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      error={false} // Disable error state
                                      helperText={null} // No helper text
                                  />
                              )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <p>End Date</p>
                          <DatePicker
                              shrink={true}
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                              maxDate={dayjs()}
                              minDate={startDate}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      // error={false} // Disable error state
                                      helperText={dateError ? 'End date cannot be before start date' : null} // Error message
                                  />
                              )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ p: 0.5, mb: 2 }}>
              <Accordion
                  elevation={0}
                  sx={accordionStyles}
                  expanded={!!expandedAccordions['panel6']}
                  onChange={handleCollapseToggle('panel6')}
              >
                <AccordionSummary
                    expandIcon={
                      <CustomExpandIcon
                      expanded={!!expandedAccordions['panel6']}

                      />
                    }
                    aria-controls="panel6-content"
                    id="panel6-header"
                    sx={{
                      ...summaryStyles,
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'none !important'
                      },
                    }}
                >
                  <BuildingIcon style={{fill: !!expandedAccordions['panel6'] ? '#205493' : undefined, marginRight: '5px', marginBottom: '0px',marginTop: '4px'}}/>
                  Facility Name / CCN
                </AccordionSummary>
                <AccordionDetails>
                  <Grid sx={{ marginTop: "10px" }} container spacing={2} style={{paddingLeft: '16px', paddingRight:'16px'}}>
                    <Grid item xs={6}>
                      {/*<>facilityNames: {facilityNames}</>*/}
                      <FormControl fullWidth variant="outlined" className="facility-name-autocomplete">
                        <InputLabel id="select-label"></InputLabel>
                        <p>Facility Name</p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="facility-name-autocomplete"
                            options={facilityNames}
                            value={selectedFacilityNames}
                            getOptionLabel={(option) => option.option.toLowerCase()}
                            filterSelectedOptions
                            onChange={(event, value) => handleChange(event, value, setSelectedFacilityNames)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedFacilityNames.length === 0 ? "Select" : ""}

                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <p>CCN</p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="ccn-autocomplete"
                            options={ccns}
                            value={selectedCCNs}
                            // getOptionLabel={(option) => option.option}
                            filterSelectedOptions
                            onChange={(event, value) => handleChange(event, value, setSelectedCCNs)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedCCNs.length === 0 ? "Select" : ""}

                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>


            <Box sx={{ p: 0.5, mb: 2 }}>
              <Accordion
                  elevation={0}
                  sx={accordionStyles}
                  expanded={!!expandedAccordions['panel7']}
                  onChange={handleCollapseToggle('panel7')}
              >
                <AccordionSummary
                    expandIcon={
                      <CustomExpandIcon
                      expanded={!!expandedAccordions['panel7']}

                      />
                    }
                    aria-controls="panel7-content"
                    id="panel7-header"
                    sx={{
                      ...summaryStyles,
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'none !important'
                      },
                    }}
                    >
                  <SurveyIcon style={{fill: !!expandedAccordions['panel7'] ? '#205493' : undefined, marginRight: '5px', marginBottom: '0px',marginTop: '4px'}}/>
                  Survey Type
                </AccordionSummary>
                <AccordionDetails>
                  <Grid sx={{ marginTop: "10px" }} container spacing={2} style={{paddingLeft: '16px', paddingRight:'16px'}}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <p>Survey Type</p>
                        <Autocomplete
                            popupIcon={<ChevronDown/>}
                            sx={selectFieldStyles}
                            multiple
                            id="tags-outlined"
                            options={surveyTypes}
                            value={selectedSurveyTypes}
                            // getOptionLabel={(option) => option.option}
                            // defaultValue={[severityLevels[0]]}
                            filterSelectedOptions
                            onChange={(event, value) => handleChange(event, value, setSelectedSurveyTypes)} // prints the selected value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={selectedSurveyTypes.length === 0 ? "Select" : ""}

                                />
                            )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div style={{display: 'flex', gap: '24px', justifyContent: 'flex-end'}}>
                    <Button variant='Primary' outline  disabled={isFiltersChanged}
                            onClick={resetAndApplyFilters}
                    >Clear & Apply
                    </Button>
                    <Button variant='Primary' disabled={isFiltersApplied}
                            onClick={applyFilters}>
                      Apply
                    </Button>
            </div>
          </Grid>
        </Grid>
      </div>
  );
};

export default Filters;