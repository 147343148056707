

export default function ExitIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.04667 6.99805L13.9649 1.07986C14.2194 0.82532 14.2194 0.443501 13.9649 0.188956C13.7103 -0.0655895 13.3285 -0.0655895 13.0739 0.188956L7.15576 6.10714L1.23758 0.188956C0.983034 -0.0655895 0.601216 -0.0655895 0.346671 0.188956C0.0921253 0.443501 0.0921253 0.82532 0.346671 1.07986L6.26485 6.99805L0.346671 12.9162C0.0921253 13.1708 0.0921253 13.5526 0.346671 13.8071C0.473944 13.9344 0.601216 13.998 0.792125 13.998C0.983034 13.998 1.11031 13.9344 1.23758 13.8071L7.15576 7.88896L13.0739 13.8071C13.2012 13.9344 13.3921 13.998 13.5194 13.998C13.6467 13.998 13.8376 13.9344 13.9649 13.8071C14.2194 13.5526 14.2194 13.1708 13.9649 12.9162L8.04667 6.99805Z" fill="#5B616B"/>
        </svg>  
    );
  }

