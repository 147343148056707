import { Box } from '@mui/material';

export const CustomExpandIcon = ({ expanded }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
      }}
    >
      {!expanded ? (
        <div className="expandIconWrapper">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_18234_10828)">
              <path fill="#0071BC" d="M8.35 5.05L7.12 6.18L11.01 10L7.16 13.82L8.35 14.97L13.36 10L8.35 5.05Z" />
            </g>
            <defs>
              <clipPath id="clip0_18234_10828">
                <rect width="19.98" height="19.99" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      ) : (
        <div className="collapsIconWrapper">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_14037_9437)">
              <path fill="#0071BC" d="M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z" />
            </g>
            <defs>
              <clipPath id="clip0_14037_9437">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </Box>
  );
};

export default CustomExpandIcon;
