import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import LoginHeader from './components/Header/LoginHeader';
import IdleTimeout from './IdleTimeout';
import WarningBanner from './components/Header/WarningBanner';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './components/Dashboards/Dashboard';
import SurveyContainer from './components/SurveyContainer/SurveyContainer';
import { SurveyProvider } from './components/SurveyContainer/SurveyContext';
import ApiTesting from './testing/ApiTesting';
import Cookies from 'js-cookie';
import { withNavigation } from './withNavigation';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            env: '',
            loginUrl: '',
            logoutUrl: '',
            user: '',
            nameTag:'',
            warned: false,
            ddbConfigurations_IdleTimeout: [],
            sessionID: '',
            isWarningBannerVisible: true,
            machine: new URL(window.location.href).origin,
            isSidebarExpanded: true,

        };
    }

    componentDidMount() {    
        if (Cookies.get('idP_validated') || sessionStorage.getItem('initialLoad')) {
            if (!sessionStorage.getItem('initialLoad')) {
                console.log('Starting session Clock');
                sessionStorage.setItem('sessionStartTime', Date.now());
                sessionStorage.setItem('initialLoad', true);
            }
            this.setState({ isWarningBannerVisible: false });
            this.setApplicationLevelValues();
            this.fetchConfigurations();
            Cookies.remove('idP_validated')
        } else {
            this.fetchLoginURL();
            fetch('/api/environment')
                .then(response => response.json())
                .then(json => {
                    this.setState({ env: json.env });
            });
            fetch('/api/status')
                .then(response => response.json())
                .then(json => {
            });
        }
    }

    fetchLoginURL = () => {
        let opts = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                machine: this.state.machine,
            })
        };
        fetch(`/auth/loginUrl`, opts)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    loginUrl: json.login,
                    logoutUrl: '',
                    user: '',
                    access_token: null,
                });
            });
    }

    loginHarporCognito = () => {
        window.open(this.state.loginUrl, '_self');
    }

    setApplicationLevelValues = () => {
        let user = Cookies.get('id');
        let atk = Cookies.get('access_token');
        let logoutUrl = Cookies.get('logout');
        let sessionID = Cookies.get('sessionID');
    
        this.fetchEncryptedUserInfo()
            .then(nonEncryptedUserName => {
            if (nonEncryptedUserName) {

                function capitalizeFirstLetterOfEachWord(str) {
                    return str.split(' ').map(word => {
                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                    }).join(' ');
                }

                const capitalizedNameTag = capitalizeFirstLetterOfEachWord(nonEncryptedUserName);
                this.setState({ nameTag: capitalizedNameTag });
            } else {

                this.setState({ nameTag: 'Jonathan Jones' });
            }
            })
          
          
                
        this.setState({
            user: user,
            logoutUrl: logoutUrl,
            access_token: atk,
            sessionID: sessionID
        });
    }

    fetchEncryptedUserInfo = async () => {
        try {
            const response = await fetch(`/api/decryptEncyptedUserInfo`);
            if (!response.ok) {
                throw new Error('Unable to decrypt User Information');
            }
            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error(error);
            return null; // Return null or some default value in case of error
        }
    };

    fetchConfigurations = () => {
        this.triggerDDBTimeoutConfig();
    }

    triggerDDBTimeoutConfig = () => {
        fetch(`/api/idle_timeout/getDDBConfigurations`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                this.setState({ 
                    ddbConfigurations_IdleTimeout: data,
                });
                let forceLogout = data[0].forceLogout;
                if (forceLogout === true) {
                    this.sharedLogout();
                }
            })
            .catch(error => {
                console.error('Failed to fetch configuration:', error);
            });
    }

    calculateSessionDuration = (storedStartTime) => {
        const startTime = parseInt(storedStartTime, 10);
        const endTime = Date.now();
        const duration = endTime - startTime;
        const durationInSeconds = Math.round(duration / 1000);
    return durationInSeconds;
    }

    sharedLogout = (statusCode, statusMessage) => {
        let logoutUrl = this.state.logoutUrl;
        let durationInSeconds = this.calculateSessionDuration(sessionStorage.getItem('sessionStartTime'));
        let epochTime = Math.floor(Date.now() / 1000);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                event: 'Logout',
                statusCode: statusCode,
                username: this.state.user,
                dateTimeUTC: new Date().toUTCString(),
                dateTimeEpoch: epochTime,
                duration: durationInSeconds,
                status: statusMessage,
                sessionID: this.state.sessionID,
            })
        };

        fetch("/auth/logout_log", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(json => {
                Cookies.remove('id');
                Cookies.remove('access_token');
                Cookies.remove('logout');
                Cookies.remove('sessionID');
                Cookies.remove('idP_validated');
                Cookies.remove('userRole');
                Cookies.remove('nameTag');

                this.setState({
                    user: '',
                    access_token: null,
                    logoutUrl: '',
                    loginUrl: '',
                    warned: false,
                });
            });

        sessionStorage.removeItem('sessionStartTime');
        sessionStorage.removeItem('initialLoad');
        sessionStorage.removeItem('elementsTabCollapsedStates');
        sessionStorage.removeItem('elementsTabScrollPosition');
        sessionStorage.removeItem('idP_validated')

        this.setState({ isWarningBannerVisible: true });
        this.fetchLoginURL();
        window.open(logoutUrl, '_self');
    }

    setWarned = (w) => {
        this.setState({ warned: w });
    }
    toggleSidebar = () => {
        this.setState((prevState) => ({
            isSidebarExpanded: !prevState.isSidebarExpanded
        }));
    }

    render() {
        let ddbWarningTime = this.state.ddbConfigurations_IdleTimeout.length !== 0 ? this.state.ddbConfigurations_IdleTimeout[0].maxInactiveTimeBeforeWarning : 25;
        let ddbLogoutTime = this.state.ddbConfigurations_IdleTimeout.length !== 0 ? this.state.ddbConfigurations_IdleTimeout[0].maxInactiveTimeBeforeLogout : 30;
        const isDdbDataAvailable = this.state.ddbConfigurations_IdleTimeout.length !== 0;
        const { location } = this.props; 
        console.log(location.pathname)
        if (!this.state.isWarningBannerVisible) {
        
            return (
                <>
                    <IdleTimeout
                        maxInactiveTimeBeforeWarning={ddbWarningTime}
                        maxInactiveTimeBeforeLogout={ddbLogoutTime}
                        ddbData={isDdbDataAvailable}
                        onTimeout={this.sharedLogout}
                    />

                    <Header {...this.state} sharedLogout={this.sharedLogout} />
                    <Container fluid style={{ height: '100vh'}}>
                        <Row style={{ height: '100%' }}>
                            <Col hidden={location.pathname === '/status'} md={1} style={{ width: this.state.isSidebarExpanded ? '150px' : '50px', padding: 0 }}>
                                <Sidebar
                                    isExpanded={this.state.isSidebarExpanded}
                                    toggleExpand={this.toggleSidebar}
                                />
                            </Col>

                            <Col style={{ padding: 0 }}>
                                <Routes>
                                    <Route path="/surveys" element={<SurveyProvider>
                                        <SurveyContainer />
                                    </SurveyProvider>} />
                                    <Route path="/dashboards" element={<Dashboard board='newsurveys' />} />
                                    <Route path="/status" element={<ApiTesting/>}/>
                                    <Route path="/" element={<Navigate to="/dashboards" />} />
                                </Routes>
                            </Col>
                        </Row>
                    </Container>
                    <br />
                </>
            );

        } else if (this.state.isWarningBannerVisible) {
            return (
                <Container className='p-1'>
                    <WarningBanner
                        warned={this.state.warned}
                        setWarned={this.setWarned}
                    />
                    <LoginHeader
                        loginHarporCognito={this.loginHarporCognito}
                    />
                </Container>
            );
        }
    }
}

export default withNavigation(App);
