export default function AnalyticsIcon({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill='none' xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M0.72719 15.998H16.1558V14.8552H1.29862V-0.00195312H0.155762V15.4266L0.72719 15.998ZM2.44148 13.1409V3.99805L3.0129 3.42662H5.29862L5.87005 3.99805V13.1409L5.29862 13.7123H3.0129L2.44148 13.1409ZM4.72719 12.5695V4.56948H3.58433V12.5695H4.72719ZM11.5843 1.71233V13.1409L12.1558 13.7123H14.4415L15.0129 13.1409V1.71233L14.4415 1.1409H12.1558L11.5843 1.71233ZM13.87 2.28376V12.5695H12.7272V2.28376H13.87ZM7.01291 13.1409V6.28376L7.58433 5.71233H9.87005L10.4415 6.28376V13.1409L9.87005 13.7123H7.58433L7.01291 13.1409ZM9.29862 12.5695V6.85519H8.15576V12.5695H9.29862Z"/>
            </svg>
    );
}
