export default function DateIcon({ style }) {
    const defaultFill = "#5B616B";  
    const fill = style?.fill || defaultFill; 

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill='none' xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M1.75 1.75H12.25C13.2165 1.75 14 2.5335 14 3.5V12.25C14 13.2165 13.2165 14 12.25 14H1.75C0.783502 14 0 13.2165 0 12.25V3.5C0 2.5335 0.783502 1.75 1.75 1.75ZM1.75 2.625C1.26675 2.625 0.875 3.01675 0.875 3.5V12.25C0.875 12.7332 1.26675 13.125 1.75 13.125H12.25C12.7332 13.125 13.125 12.7332 13.125 12.25V3.5C13.125 3.01675 12.7332 2.625 12.25 2.625H1.75Z"/>
            <path fill={fill} d="M1.75 0H2.625V1.75H1.75V0Z"/>
            <path fill={fill} d="M10.5 9.625H12.25V11.375H10.5V9.625Z"/>
            <path fill={fill} d="M11.375 0H12.25V1.75H11.375V0Z"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M1.3125 5.25C1.3125 5.00838 1.50838 4.8125 1.75 4.8125H12.25C12.4916 4.8125 12.6875 5.00838 12.6875 5.25C12.6875 5.49162 12.4916 5.6875 12.25 5.6875H1.75C1.50838 5.6875 1.3125 5.49162 1.3125 5.25Z"/>
        </svg>
    );
}

  