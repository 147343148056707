export default function LocationIcon({ style }) {
    const defaultFill = "#5B616B";  // Default color for the icon
    const fill = style?.fill || defaultFill;  // Use passed fill color or default

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill='none' xmlns="http://www.w3.org/2000/svg" style={style}>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M5.39145 4.37633C4.98295 4.67301 4.79806 5.02969 4.79806 5.36315C4.79806 5.69662 4.98295 6.0533 5.39145 6.34998C5.79929 6.64618 6.39354 6.84854 7.07792 6.84854C7.7623 6.84854 8.35654 6.64618 8.76439 6.34998C9.17289 6.0533 9.35778 5.69662 9.35778 5.36315C9.35778 5.02969 9.17289 4.67301 8.76439 4.37633C8.35654 4.08013 7.7623 3.87777 7.07792 3.87777C6.39354 3.87777 5.79929 4.08013 5.39145 4.37633ZM4.65986 3.36901C5.30246 2.90232 6.15938 2.63281 7.07792 2.63281C7.99646 2.63281 8.85338 2.90232 9.49597 3.36901C10.1379 3.83523 10.6027 4.53248 10.6027 5.36315C10.6027 6.19383 10.1379 6.89108 9.49597 7.3573C8.85338 7.82399 7.99646 8.0935 7.07792 8.0935C6.15938 8.0935 5.30246 7.82399 4.65986 7.3573C4.01792 6.89108 3.5531 6.19383 3.5531 5.36315C3.5531 4.53248 4.01792 3.83523 4.65986 3.36901Z" fill="#5B616B"/>
            <path fill={fill} flipRule="evenodd" clipRule="evenodd" d="M1.26728 4.9218C1.18454 5.58279 1.32552 6.33496 1.80926 7.15473C2.87751 8.96499 4.30967 10.9166 5.48303 12.4243C6.06814 13.1761 6.58577 13.8136 6.95686 14.2628C6.99914 14.3139 7.0395 14.3627 7.07785 14.4088C7.11619 14.3627 7.15655 14.3139 7.19883 14.2628C7.56992 13.8136 8.08755 13.1761 8.67266 12.4243C9.84602 10.9166 11.2782 8.96499 12.3464 7.15473C12.8302 6.33496 12.9712 5.58279 12.8884 4.9218C12.8053 4.25758 12.4898 3.63475 11.976 3.08936C10.9379 1.98754 9.12052 1.243 7.07785 1.243C5.03517 1.243 3.21777 1.98754 2.17974 3.08936C1.66594 3.63475 1.35042 4.25758 1.26728 4.9218ZM7.07785 15.3756C6.60602 15.7816 6.60598 15.7816 6.60593 15.7815L6.59467 15.7684L6.56296 15.7313C6.53524 15.6988 6.49456 15.6509 6.44217 15.5889C6.33738 15.4648 6.18571 15.284 5.99708 15.0557C5.61991 14.5992 5.09451 13.9521 4.50055 13.1889C3.31576 11.6665 1.84558 9.66594 0.737071 7.78743C0.132443 6.76282 -0.0893718 5.73648 0.0319601 4.76717C0.152888 3.80109 0.608305 2.94183 1.27358 2.23567C2.5937 0.834411 4.76701 -0.00195312 7.07785 -0.00195312C9.38868 -0.00195312 11.562 0.834411 12.8821 2.23567C13.5474 2.94183 14.0028 3.80109 14.1237 4.76717C14.2451 5.73648 14.0232 6.76282 13.4186 7.78743C12.3101 9.66594 10.8399 11.6665 9.65514 13.1889C9.06118 13.9521 8.53578 14.5992 8.15862 15.0557C7.96999 15.284 7.81831 15.4648 7.71353 15.5889C7.66113 15.6509 7.62045 15.6988 7.59273 15.7313L7.56102 15.7684L7.54994 15.7813C7.54989 15.7813 7.54967 15.7816 7.07785 15.3756ZM7.07785 15.3756L7.54994 15.7813C7.43169 15.9187 7.25913 15.998 7.07785 15.998C6.89656 15.998 6.72418 15.9189 6.60593 15.7815L7.07785 15.3756Z"/>
        </svg>
    );
}