//Libraries
import React, {useEffect, useState} from 'react';
import ResizeHandle from "./ResizeHandle";
import { Panel, PanelGroup } from "react-resizable-panels";
import { IconButton, Collapse   } from '@mui/material';
//Components
import Filters from './Filters/Filters';
import SurveyInfoPanel from './SurveyInfo/SurveyInfo';
import SurveySelectionPanel from './SurveySelection/SurveySelectionPanel';
import FilterIcon from '../../assets/icons/FilterIcon';
import ExitIcon from '../../assets/icons/ExitIcon';
//Styles
import styles from "./styles.module.css";
import '../../variable.css';

const SurveyContainer = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleToggle = () => {
    setFiltersOpen(!filtersOpen);
  };

    return (

      
        
<div 
  style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    height: '100vh', 
    border: 'solid #D6D7D9 10pt', 
    width: '100%', 
    minHeight: 0, 
    boxSizing: 'border-box', // Include border in the width and height calculations
    padding: '24px',
    position: 'relative',
  }}
>
  {/* <h1 style={{ fontWeight: '700', fontSize: '20px', color: '#205493' }}>
    SURVEYS
  </h1> */}
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
    <h1 style={{ fontWeight: '700', fontSize: '20px', color: '#205493', padding: '4px 8px', margin: 0 }}>
      SURVEYS
    </h1>
    <IconButton style={{ marginLeft: 'auto' }} onClick={handleToggle}>
      {filtersOpen ? <ExitIcon /> : <FilterIcon style={{ fill: '#0071BC' }} />}
    </IconButton>
  </div>
  <hr style={{ border: '1px solid #C3C5C6', opacity: 1 }} />
  
    <Collapse in={filtersOpen} timeout="auto">
        <div 
          style={{ 
            position: 'absolute',
            top: `75px`, 
            left: 0,
            right: 0,
            backgroundColor: 'white', 
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', 
            zIndex: 10,
            padding: '16px',
          }}
        >
          <Filters handleToggle={handleToggle}/>
        </div>
      </Collapse>


    <PanelGroup direction="horizontal" style={{ flex: '1 1 auto', overflow: 'hidden' }}>
      <Panel className={styles.Panel} order={1} defaultSize={30} minSize={25} collapsible={false}>
        <div className={styles.PanelContent} style={{ height: '100%' }}>
          <SurveySelectionPanel />
        </div>
      </Panel>
      <ResizeHandle style={{ cursor: 'col-resize' }} />
      <Panel className={styles.Panel} order={2} minSize={55} collapsible={false}>
        <div className={styles.PanelContent} style={{ height: '100%' }}>
          <SurveyInfoPanel />
        </div>
      </Panel>
    </PanelGroup>
    </div>
    );
};

export default SurveyContainer;
